import { Navigate, Route, Routes } from 'react-router-dom'

import CreateCompanyPage from '../../pages/CreateCompany/CreateCompany.page'
import CreatePlanPage from '../../pages/CreatePlan/CreatePlan.page'
import { Flex } from 'antd'
import NavbarNewUser from '../NewUserNavbar/NewUserNavbar'
import { NewUserState } from '../../types'
import VerifyUserInfoPage from '../../pages/VerifyUserInfo/VerifyUserInfo.page'
import { config } from '../../config'
import { observer } from 'mobx-react'
import userStore from '../../stores/user.store'

const NewUserRoute = observer(() => {
  const { verifyUserInfoPath, createCompanyPath, createPlanPath } = config.path

  const { user } = userStore

  return (
    <Flex vertical>
      <NavbarNewUser />
      <Routes>
        <Route path={verifyUserInfoPath} element={<VerifyUserInfoPage />} />
        <Route path={createCompanyPath} element={<CreateCompanyPage />} />
        <Route path={createPlanPath} element={<CreatePlanPage />} />
        <Route
          path="/*"
          element={
            <Navigate
              replace
              to={
                user.newUserState === NewUserState.VERIFY_USER_INFO
                  ? verifyUserInfoPath
                  : user.newUserState === NewUserState.CREATE_COMPANY
                  ? createCompanyPath
                  : createPlanPath
              }
            />
          }
        />
      </Routes>
    </Flex>
  )
})

export default NewUserRoute
