import { darkThemeConfig, lightThemeConfig } from '../config/theme.config'

import { Theme } from '../types'
import { ThemeConfig } from 'antd'
import { makeAutoObservable } from 'mobx'

class ThemeStore {
  theme: Theme = (localStorage.getItem('theme') as Theme) || Theme.LIGHT

  constructor() {
    makeAutoObservable(this)
    if (!localStorage.getItem('theme')) {
      localStorage.setItem('theme', Theme.LIGHT)
      document.documentElement.dataset['theme'] = Theme.LIGHT
    } else {
      document.documentElement.dataset['theme'] = this.theme
    }
  }

  get themeConfig(): ThemeConfig {
    return this.theme === Theme.LIGHT ? lightThemeConfig : darkThemeConfig
  }

  toggleTheme = () => {
    const setTheme: Theme =
      this.theme === Theme.LIGHT ? Theme.DARK : Theme.LIGHT
    this.theme = setTheme
    document.documentElement.dataset['theme'] = setTheme
    localStorage.setItem('theme', setTheme)
  }
}
const themeStore = new ThemeStore()
export default themeStore
