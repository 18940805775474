export type User = {
  email: string
  firstName: string
  lastName: string
  picture: string
  newUserState: NewUserState
}

export enum NewUserState {
  VERIFY_USER_INFO = 'verify-user-info',
  CREATE_COMPANY = 'create-company',
  CREATE_PLAN = 'create-plan',
  COMPLETE = 'complete',
}
