import { PlanTemplate } from '../types'

const env = {
  REACT_APP_NODE_ENV: process.env.REACT_APP_NODE_ENV!,
  REACT_APP_FINANCIAL_PLANNING_SERVICE_URL:
    process.env.REACT_APP_FINANCIAL_PLANNING_SERVICE_URL!,
  REACT_APP_KEYCLOAK_URL: process.env.REACT_APP_KEYCLOAK_URL!,
  REACT_APP_KEYCLOAK_REALM: process.env.REACT_APP_KEYCLOAK_REALM!,
  REACT_APP_KEYCLOAK_CLIENT_ID: process.env.REACT_APP_KEYCLOAK_CLIENT_ID!,
}

const config = {
  app: { env: env.REACT_APP_NODE_ENV },
  path: {
    verifyUserInfoPath: '/verify-user-info',
    createCompanyPath: '/create-company',
    createPlanPath: '/create-plan',
    reportPath: '/report',
    dashboardPath: '/dashboard',
    platformPath: '/platform',
    howToUsePath: '/how-to-use',
    memberPath: '/member',
    profilePath: '/profile',
    settingPath: '/setting',
  },
  financialPlanningService: {
    url: env.REACT_APP_FINANCIAL_PLANNING_SERVICE_URL,
  },
  keycloak: {
    url: env.REACT_APP_KEYCLOAK_URL,
    realm: env.REACT_APP_KEYCLOAK_REALM,
    clientId: env.REACT_APP_KEYCLOAK_CLIENT_ID,
  },
  color: {
    light: {
      // Brand Color
      brandBlue: '#00B4FF',
      brandLomrBlue: '#5000FF',
      brandPink: '#F002FD',
      brandRed: '#F00',
      // Primary
      primaryPurple: '#5000FF',
      primaryHoverPurple: '#3900B5',
      // Secondary
      secondaryBlue: '#128EFF',
      secondaryHoverBlue: '#0D65B5',
      // Surface System
      systemSurfaceSelected: '#F8F4FF',
      systemSurfaceHoverPress: '#F4F6F8',
      // Surface
      surfacePurple1: '#FBF9FF',
      surfaceBlue1: '#E7F4FF',
      surfaceGreen1: '#E6F9EC',
      surfaceYellow1: '#FFF4E6',
      surfaceRed1: '#FFECEC',
      surfaceGray1: '#E5E9EE',
      surfacePurple2: '#F8F4FF',
      surfaceBlue2: '#C6E4FF',
      surfaceGreen2: '#C2F0D1',
      surfaceYellow2: '#FFE5C2',
      surfaceRed2: '#FFD2D2',
      surfaceGray2: '#D1D7E0',
      // Background
      backgroundBgGray: '#F4F6F8',
      backgroundBgWhite: '#FFF',
      backgroundContainer: '#FFF',
      // Status
      statusGreen: '#00A236',
      statusYellow: '#FF9200',
      statusRed: '#FF4242',
      // Border
      strokePurple: '#B491FF',
      strokeBlue: '#99CEFF',
      strokeGray: '#E5E9EE',
      // Text / Icon
      textIconMain: '#0D0D0D',
      textIconDetail: '#687482',
      textIconCaption: '#93A3B7',
      textIconPlaceholder: '#A6B4C4',
      textIconDisable: '#93A3B7',
      // Common
      commonBlack: '#0D0D0D',
      commonWhite: '#FFF',
      // Shadow
      normalShadow: '#e5e9eeb3',
      heavyShadow: '#E5E9EE',
      // Overlay
      mainOverlay: '#0d0d0d66',
      tooltipOverlay: '#0d0d0dbf',
    },
    dark: {
      // Brand Color
      brandBlue: '#00B4FF',
      brandLomrBlue: '#5000FF',
      brandPink: '#F002FD',
      brandRed: '#F00',
      // Primary
      primaryPurple: '#B491FF',
      primaryHoverPurple: '#915EFF',
      // Secondary
      secondaryBlue: '#128EFF',
      secondaryHoverBlue: '#0D65B5',
      // Surface System
      systemSurfaceSelected: '#0D0D0D',
      systemSurfaceHoverPress: '#0D0D0D',
      // Surface
      surfacePurple1: '#0D0D0D',
      surfaceBlue1: '#0D0D0D',
      surfaceGreen1: '#0D0D0D',
      surfaceYellow1: '#0D0D0D',
      surfaceRed1: '#0D0D0D',
      surfaceGray1: '#0D0D0D',
      surfacePurple2: '#0D0D0D',
      surfaceBlue2: '#0D0D0D',
      surfaceGreen2: '#0D0D0D',
      surfaceYellow2: '#0D0D0D',
      surfaceRed2: '#0D0D0D',
      surfaceGray2: '#0D0D0D',
      // Background
      backgroundBgGray: '#0D0D0D',
      backgroundBgWhite: '#1B1B1B',
      backgroundContainer: '#1B1B1B',
      // Status
      statusGreen: '#00A236',
      statusYellow: '#FF9200',
      statusRed: '#FF4242',
      // Border
      strokePurple: '#2F343A',
      strokeBlue: '#2F343A',
      strokeGray: '#2F343A',
      // Text / Icon
      textIconMain: '#0D0D0D',
      textIconDetail: '#687482',
      textIconCaption: '#93A3B7',
      textIconPlaceholder: '#A6B4C4',
      textIconDisable: '#93A3B7',
      // Common
      commonBlack: '#0D0D0D',
      commonWhite: '#FFF',
      // Shadow
      normalShadow: '#e5e9eeb3',
      heavyShadow: '#E5E9EE',
      // Overlay
      mainOverlay: '#0d0d0d66',
      tooltipOverlay: '#0d0d0dbf',
    },
  },
  planTemplate: {
    [PlanTemplate.CONSTRUCTION_CONTRACTOR]: [
      '41001',
      '41002',
      '42101',
      '42102',
      '42201',
      '42202',
      '42901',
      '42909',
      '43120',
      '43299',
      '43901',
      '43909',
    ],
    [PlanTemplate.ELECTRICAL_STORE]: [
      '46104',
      '46421',
      '46422',
      '46510',
      '46521',
      '46522',
      '47593',
      '47595',
      '47743',
      '47744',
      '47892',
    ],
    [PlanTemplate.CAFE]: [
      '46316',
      '46317',
      '46318',
      '47215',
      '47222',
      '47812',
      '56302',
    ],
    [PlanTemplate.LIQUOR_STORE_OR_PUB]: [
      '46321',
      '47221',
      '56301',
      '56303',
      '56304',
    ],
    [PlanTemplate.CLOTHING_STORE]: [
      '46411',
      '46414',
      '46415',
      '47511',
      '47711',
      '47712',
      '47731',
      '47732',
      '47742',
      '47822',
    ],
    [PlanTemplate.PHARMACY]: ['46441', '46442', '47721', '47895'],
    [PlanTemplate.COSMETIC_STORE]: ['46443', '47722', '47723'],
    [PlanTemplate.PET_STORE]: ['47734', '47897'],
    [PlanTemplate.ONLINE_STORE]: [
      '47911',
      '47912',
      '47999',
      '58122',
      '58131',
      '58192',
    ],
    [PlanTemplate.GENERAL_RESTAURANT]: ['56101', '56102', '56103'],
    [PlanTemplate.CATERING]: ['56210'],
    [PlanTemplate.HOUSE_CONDO_APARTMENT_OFFICE_FOR_RENT]: ['68103', '68104'],
    [PlanTemplate.REAL_ESTATE_AGENT]: ['68201'],
  },
}

export { config, env }
