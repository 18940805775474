import './Navbar.css'

import { CloseOutlined, DownOutlined, MenuOutlined } from '@ant-design/icons'
import { Flex, Popover } from 'antd'

import CompanyAndPlanPopover from '../CompanyAndPlanPopover/CompanyAndPlanPopover'
import PictureProfile from '../PictureProfile/PictureProfile'
import UserPopover from '../UserPopover/UserPopover'
import companiesStore from '../../stores/companies.store'
import { observer } from 'mobx-react'
import plansStore from '../../stores/plans.store'
import sidebarStore from '../../stores/sidebar.store'
import { useParams } from 'react-router-dom'
import userStore from '../../stores/user.store'

const Navbar = observer(() => {
  const {
    mobileSidebarCollapsed,
    unCollapseMobileSidebar,
    collapseMobileSidebar,
  } = sidebarStore
  const { companies } = companiesStore
  const { plans } = plansStore
  const { user } = userStore
  const { firstName, picture } = user

  const { companyId: currentCompanyId, planId: currentPlanId } = useParams<{
    companyId: string
    planId: string
  }>()

  const onMobileSidebarClick = () => {
    if (mobileSidebarCollapsed) {
      unCollapseMobileSidebar()
    } else {
      collapseMobileSidebar()
    }
  }

  return (
    <Flex className="navbar-cmpt">
      <div className="logo">LOGO</div>
      <Flex className="management font-size-body-2-regular font-color-text-icon-detail">
        <Flex>
          <span>บริษัท:</span>
          <span className="font-color-text-icon-main">
            {currentCompanyId
              ? companies.find(
                  (company) => company.companyId === currentCompanyId,
                )?.name
              : 'ไม่มีบริษัท'}
          </span>
          <Popover
            placement="bottomRight"
            arrow={false}
            content={
              <CompanyAndPlanPopover
                list={companies.map((company) => ({
                  ...company,
                  id: company.companyId,
                }))}
                currentId={currentCompanyId || ''}
                btnText="ดูบริษัททั้งหมด"
                addFrame={true}
              />
            }
          >
            <DownOutlined />
          </Popover>
        </Flex>
        <Flex>
          <span>แผนธุรกิจ:</span>
          <span className="font-color-text-icon-main">
            {currentPlanId
              ? plans.find((plan) => plan.planId === currentPlanId)?.name
              : 'ไม่มีแผน'}
          </span>
          <Popover
            placement="bottomRight"
            arrow={false}
            content={
              <CompanyAndPlanPopover
                isCompany={false}
                list={plans
                  .filter((plan) => plan.companyId === currentCompanyId)
                  .map((plan) => ({
                    ...plan,
                    id: plan.planId,
                  }))}
                currentId={currentPlanId || ''}
                btnText="ดูแผนธุรกิจทั้งหมด"
                addFrame={true}
              />
            }
          >
            <DownOutlined />
          </Popover>
        </Flex>
        <Popover
          placement="bottomRight"
          arrow={false}
          content={<UserPopover />}
        >
          <PictureProfile
            picture={picture}
            firstName={firstName}
            size={40}
            isHover={true}
          />
        </Popover>
      </Flex>
      <Flex
        className="management-mobile-sidebar font-color-text-icon-detail"
        onClick={onMobileSidebarClick}
      >
        {mobileSidebarCollapsed ? <MenuOutlined /> : <CloseOutlined />}
      </Flex>
    </Flex>
  )
})

export default Navbar
