import './Dashboard.css'

import { useLocation, useParams } from 'react-router-dom'

import { Flex } from 'antd'

const DashboardPage = () => {
  const { companyId, planId } = useParams()
  const { pathname } = useLocation()
  const rootPath = pathname.split('/')[1]

  return (
    <Flex className="dashboard-page">
      {rootPath} {companyId} - {planId}
    </Flex>
  )
}

export default DashboardPage
