import './Member.css'

import { Access, AccessStatus, AccessType } from '../../types'
import {
  Alert,
  Button,
  Drawer,
  Flex,
  Form,
  FormProps,
  Input,
  Modal,
  Select,
  Spin,
  Switch,
  Table,
  message,
} from 'antd'
import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import {
  createAccess,
  deleteAccess,
  getAllAccessesByPlan,
  resendAccessCode,
  updateAccess,
  verifyAccess,
} from '../../services/access.service'
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { AxiosError } from 'axios'
import LoadingBrandColor from '../../components/LoadingBrandColor/LoadingBrandColor'
import PictureProfile from '../../components/PictureProfile/PictureProfile'
import { config } from '../../config'
import { differenceSeconds } from '../../utils/date.util'
import plansStore from '../../stores/plans.store'
import userStore from '../../stores/user.store'

const { Option } = Select
type CreateAccessForm = { email: string; type: AccessType }
type VerifyAccessForm = { accessId: string; code: string }
type EditAccessForm = {
  accessId: string
  type: AccessType
  deactivate: boolean
}

const MemberPage = () => {
  const codeExpire = 120
  const { user } = userStore
  const { firstName, lastName, picture, email } = user
  const { plans } = plansStore
  const { settingPath } = config.path

  const navigate = useNavigate()

  const { planId: currentPlanId } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [accesses, setAccesses] = useState<Access[]>([])

  const [isCreateAccessOpen, setIsCreateAccessOpen] = useState(false)
  const [isLoadingCreateAccess, setIsLoadingCreateAccess] = useState(false)
  const [createAccessEmail, setCreateAccessEmail] = useState('')
  const [accessVerificationRef, setAccessVerificationRef] = useState('')
  const [createAccessForm] = Form.useForm()

  const [isVerifyAccessOpen, setIsVerifyAccessOpen] = useState(false)
  const [isLoadingVerifyAccess, setIsLoadingVerifyAccess] = useState(false)
  const [verifyAccessForm] = Form.useForm()

  const [isEditAccessOpen, setIsEditAccessOpen] = useState(false)
  const [isLoadingEditAccess, setIsLoadingEditAccess] = useState(false)
  const [editName, setEditName] = useState('')
  const [editAccessForm] = Form.useForm()

  const [isLoadingResendAccessCode, setIsLoadingResendAccessCode] =
    useState(false)
  const [countTime, setCountTime] = useState(0)
  const timeOutId = useRef({})

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [deleteId, setDeleteId] = useState('')
  const [deleteName, setDeleteName] = useState('')
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)

  const [messageApi, contextHolder] = message.useMessage()

  useEffect(() => {
    if (currentPlanId) {
      setIsLoading(true)
      getAllAccessesByPlan(currentPlanId)
        .then((response) => {
          setAccesses([
            {
              accessId: '',
              recipientEmail: email,
              recipientFirstName: firstName,
              recipientLastName: lastName,
              recipientPicture: picture,
              type: AccessType.OWNER,
              status: AccessStatus.ACTIVE,
              createdAt: '',
              updatedAt: '',
            },
            ...response.data,
          ])
        })
        .finally(() => setIsLoading(false))
    } else {
      setIsLoading(false)
      setAccesses([])
    }
  }, [email, firstName, lastName, picture, currentPlanId])

  useEffect(() => {
    if (countTime > 0) {
      timeOutId.current = setTimeout(() => {
        setCountTime(countTime - 1)
      }, 1000)
    }
  }, [countTime])

  const onFinishCreateAccess: FormProps<CreateAccessForm>['onFinish'] = (
    _createAccessForm: CreateAccessForm,
  ) => {
    const { email, type } = _createAccessForm

    if (email === user.email) {
      createAccessForm.setFields([
        {
          name: 'email',
          errors: ['ไม่สามารถเชิญอีเมล์นี้ได้'],
        },
      ])
      return
    }

    setIsLoadingCreateAccess(true)
    createAccess(currentPlanId!, email, type)
      .then((response) => {
        accesses.push(response.data)
        setIsCreateAccessOpen(false)
        createAccessForm.setFieldsValue({
          email: undefined,
          type: undefined,
        })

        const { accessId, recipientEmail, verificationRef } = response.data
        setIsVerifyAccessOpen(true)
        typeof timeOutId.current === 'number' && clearTimeout(timeOutId.current)
        setCountTime(codeExpire)
        setCreateAccessEmail(recipientEmail)
        setAccessVerificationRef(verificationRef || '')
        verifyAccessForm.setFieldsValue({
          accessId,
          code: undefined,
        })
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 422) {
          createAccessForm.setFields([
            {
              name: 'email',
              errors: [
                'อีเมล์นี้ยังไม่ได้เป็นสมาชิก โปรดสมัครสมาชิกก่อนเชิญเข้าทีม',
              ],
            },
          ])
        } else if (error.response?.status === 409) {
          createAccessForm.setFields([
            {
              name: 'email',
              errors: ['ไม่สามารถเชิญซ้ำได้'],
            },
          ])
        }
      })
      .finally(() => {
        setIsLoadingCreateAccess(false)
      })
  }

  const onFinishVerifyAccess: FormProps<VerifyAccessForm>['onFinish'] = (
    _verifyAccessForm: VerifyAccessForm,
  ) => {
    const { accessId, code } = _verifyAccessForm
    setIsLoadingVerifyAccess(true)
    verifyAccess(accessId, code)
      .then((response) => {
        setAccesses(
          accesses.map((access) => {
            const { accessId } = response.data
            return access.accessId === accessId ? response.data : access
          }),
        )
        setIsVerifyAccessOpen(false)
        verifyAccessForm.setFieldsValue({
          accessId: undefined,
          code: undefined,
        })
        messageApi.open({
          type: 'success',
          content: 'เชิญสมาชิกเข้าทีมเรียบร้อยแล้ว',
        })
      })
      .catch((error: AxiosError<{ message: string }>) => {
        if (error.response?.status === 422) {
          const errorMessage =
            error.response.data.message === 'Code is invalid'
              ? 'รหัสยืนยันตัวตนไม่ถูกต้อง'
              : 'รหัสยืนยันตัวตนหมดอายุ'

          verifyAccessForm.setFields([
            {
              name: 'code',
              errors: [errorMessage],
            },
          ])
        }
      })
      .finally(() => {
        setIsLoadingVerifyAccess(false)
      })
  }

  const onClickResendCode = () => {
    if (countTime === 0) {
      setIsLoadingResendAccessCode(true)
      resendAccessCode(verifyAccessForm.getFieldValue('accessId'))
        .then((response) => {
          setAccesses(
            accesses.map((access) => {
              const { accessId } = response.data
              return access.accessId === accessId ? response.data : access
            }),
          )
          setAccessVerificationRef(response.data.verificationRef || '')
          messageApi.open({
            type: 'success',
            content: `ส่งรหัสยืนยันตัวตนไปที่อีเมล์ ${response.data.recipientEmail} เรียบร้อยแล้ว`,
          })
        })
        .finally(() => {
          typeof timeOutId.current === 'number' &&
            clearTimeout(timeOutId.current)
          setCountTime(codeExpire)
          setIsLoadingResendAccessCode(false)
        })
    }
  }

  const onClickDeleteAccess = () => {
    setIsLoadingDelete(true)
    deleteAccess(deleteId)
      .then(() => {
        const deletedIndex = accesses.findIndex(
          (access) => access.accessId === deleteId,
        )
        if (deletedIndex !== -1) {
          accesses.splice(deletedIndex, 1)
        }
        messageApi.open({
          type: 'success',
          content: 'ลบเรียบร้อยแล้ว',
        })
      })
      .finally(() => {
        setIsLoadingDelete(false)
        setIsDeleteModalOpen(false)
      })
  }

  const onClickOpenDeleteModal = (accessId: string, name: string) => {
    setIsDeleteModalOpen(true)
    setDeleteId(accessId)
    setDeleteName(name)
  }

  const onClickOpenEditAccess = (
    accessId: string,
    status: AccessStatus,
    email: string,
    updatedAt: string,
    name: string,
    type: AccessType,
    verificationRef?: string,
  ) => {
    if (status === AccessStatus.VERIFYING) {
      const diffTime = differenceSeconds(new Date(updatedAt), new Date())
      if (diffTime < codeExpire) {
        typeof timeOutId.current === 'number' && clearTimeout(timeOutId.current)
        setCountTime(codeExpire - Math.floor(diffTime))
      } else {
        typeof timeOutId.current === 'number' && clearTimeout(timeOutId.current)
        setCountTime(0)
      }
      verifyAccessForm.setFieldsValue({
        accessId,
        code: undefined,
      })
      setCreateAccessEmail(email)
      setAccessVerificationRef(verificationRef || '')
      setIsVerifyAccessOpen(true)
    } else {
      setEditName(name)
      editAccessForm.setFieldsValue({
        accessId,
        type,
        deactivate: status === AccessStatus.INACTIVE,
      })
      setIsEditAccessOpen(true)
    }
  }

  const onFinishEditAccess: FormProps<EditAccessForm>['onFinish'] = (
    _editAccessForm: EditAccessForm,
  ) => {
    const { accessId, type, deactivate } = _editAccessForm
    setIsLoadingEditAccess(true)
    updateAccess(accessId, type, deactivate)
      .then((response) => {
        setAccesses(
          accesses.map((access) => {
            const { accessId } = response.data
            return access.accessId === accessId ? response.data : access
          }),
        )
        messageApi.open({
          type: 'success',
          content: 'แก้ไขเรียบร้อยแล้ว',
        })
      })
      .finally(() => {
        setIsLoadingEditAccess(false)
        setIsEditAccessOpen(false)
      })
  }

  if (isLoading) {
    return <LoadingBrandColor isOnSettingPage={true} />
  }

  return (
    <Flex className="member-page" gap={16} vertical>
      <Flex align="center" justify="space-between">
        <span className="font-size-h2-bold font-color-text-icon-main">
          สมาชิกในทีม
          {currentPlanId
            ? `: ${plans.find((plan) => plan.planId === currentPlanId)?.name}`
            : ''}
        </span>
        <Button
          disabled={!currentPlanId}
          type="primary"
          onClick={() => {
            createAccessForm.resetFields()
            setIsCreateAccessOpen(true)
          }}
        >
          เชิญสมาชิก
        </Button>
      </Flex>

      <Flex vertical className="all-member">
        <Table
          scroll={{ x: 900 }}
          className="member-table"
          pagination={false}
          dataSource={accesses.map((access, key) => ({
            ...access,
            key,
          }))}
          columns={[
            {
              title: 'ชื่อ',
              width: '25%',
              ellipsis: true,
              className: 'first-column',
              render: (access: Access) => (
                <Flex gap={8} align="center">
                  <PictureProfile
                    picture={access.recipientPicture}
                    firstName={access.recipientFirstName}
                    size={40}
                  />
                  <span
                    className={`font-size-body-2-medium font-color-text-icon-${
                      access.status === AccessStatus.INACTIVE
                        ? 'disable'
                        : 'main'
                    }`}
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      lineHeight: '24px',
                    }}
                  >
                    {access.recipientFirstName} {access.recipientLastName}
                  </span>
                </Flex>
              ),
            },
            {
              title: 'อีเมล์',
              width: '20%',
              ellipsis: true,
              render: (access: Access) => (
                <span
                  className={`font-size-body-2-regular font-color-text-icon-${
                    access.status === AccessStatus.INACTIVE ? 'disable' : 'main'
                  }`}
                >
                  {access.recipientEmail}
                </span>
              ),
            },
            {
              title: 'บทบาท',
              width: '15%',
              render: (access: Access) => (
                <span
                  className={`status-badge ${access.type} ${access.status} font-size-tooltip-regular`}
                >
                  {access.type === AccessType.OWNER
                    ? 'เจ้าของ'
                    : access.type === AccessType.CO_WORKER
                    ? 'ผู้ช่วยหลัก'
                    : 'ดูเท่านั้น'}
                </span>
              ),
            },
            {
              title: 'สถานะ',
              width: '15%',
              render: (access: Access) => (
                <span
                  className={`font-size-body-2-regular font-color-text-icon-${
                    access.status === AccessStatus.INACTIVE ? 'disable' : 'main'
                  }`}
                >
                  {access.status === AccessStatus.ACTIVE
                    ? 'เข้าร่วมแล้ว'
                    : access.status === AccessStatus.INACTIVE
                    ? 'ปิดการใช้งาน'
                    : differenceSeconds(
                        new Date(access.updatedAt),
                        new Date(),
                      ) > codeExpire
                    ? 'คำเชิญหมดอายุ'
                    : 'รอยืนยันตัวตน'}
                </span>
              ),
            },
            {
              title: 'วันเข้าร่วมทีม',
              width: '15%',
              render: (access: Access) => (
                <span
                  className={`font-size-body-2-regular font-color-text-icon-${
                    access.status === AccessStatus.INACTIVE ? 'disable' : 'main'
                  }`}
                >
                  {access.status !== AccessStatus.VERIFYING &&
                  access.type !== AccessType.OWNER
                    ? new Date(access.updatedAt).toLocaleDateString('th-TH', {
                        year: 'numeric',
                        month: 'narrow',
                        day: 'numeric',
                      })
                    : ''}
                </span>
              ),
            },
            {
              title: 'การจัดการ',
              width: '10%',
              align: 'end',
              className: 'last-column',
              render: (access: Access) => (
                <>
                  {access.type !== AccessType.OWNER ? (
                    <Flex gap={20} align="end" vertical>
                      <Flex gap={24} className="icon-management">
                        <EditOutlined
                          onClick={() =>
                            onClickOpenEditAccess(
                              access.accessId,
                              access.status,
                              access.recipientEmail,
                              access.updatedAt,
                              `${access.recipientFirstName} ${access.recipientLastName}`,
                              access.type,
                              access.verificationRef,
                            )
                          }
                        />
                        <DeleteOutlined
                          onClick={() =>
                            onClickOpenDeleteModal(
                              access.accessId,
                              `${access.recipientFirstName} ${access.recipientLastName}`,
                            )
                          }
                        />
                      </Flex>
                    </Flex>
                  ) : (
                    ''
                  )}
                </>
              ),
            },
          ]}
          locale={{
            emptyText: (
              <Flex
                align="center"
                vertical
                style={{ margin: '16px 0px 40px 0px' }}
              >
                <div className="no-data-icon" />
                <span
                  className="font-size-body-2-bold font-color-text-icon-main"
                  style={{ lineHeight: '24px' }}
                >
                  ยังไม่มีแผนธุรกิจ
                </span>
                <span
                  className="font-size-body-2-regular font-color-text-icon-detail"
                  style={{ lineHeight: '24px' }}
                >
                  เพิ่มแผนธุรกิจ เพื่อเริ่มต้นใช้งาน FP&A
                </span>
                <Button
                  type="primary"
                  size="small"
                  icon={<PlusOutlined />}
                  style={{ marginTop: 16 }}
                  onClick={() => navigate(settingPath)}
                >
                  เพิ่มแผนธุรกิจ
                </Button>
              </Flex>
            ),
          }}
        />
      </Flex>

      <Drawer
        title="เชิญสมาชิก"
        placement="right"
        maskClosable={false}
        width={472}
        onClose={() => setIsCreateAccessOpen(false)}
        open={isCreateAccessOpen}
      >
        <Alert
          description="กรอกอีเมล์สมาชิกที่จะเชิญเข้าร่วมทีม สมาชิกที่จะเข้าร่วมทีมได้ จำเป็นต้องสมัครเป็นสมาชิกก่อนเท่านั้น"
          type="info"
          showIcon
          style={{ marginBottom: 24 }}
        />
        <Form
          form={createAccessForm}
          layout="vertical"
          onFinish={onFinishCreateAccess}
        >
          <Form.Item
            label="อีเมล์"
            name="email"
            rules={[{ required: true, message: 'โปรดกรอกอีเมล์' }]}
          >
            <Input placeholder="กรอกอีเมล์" allowClear />
          </Form.Item>
          <Form.Item
            label="บทบาท"
            name="type"
            rules={[{ required: true, message: 'โปรดเลือกบทบาท' }]}
          >
            <Select placeholder="เลือกบทบาท" allowClear>
              <Option value={AccessType.CO_WORKER}>
                ผู้ช่วยหลัก (Co-worker)
              </Option>
              <Option value={AccessType.VIEWER}>ดูเท่านั้น (Viewer)</Option>
            </Select>
          </Form.Item>
          <Flex justify="end" style={{ marginTop: 40 }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoadingCreateAccess}
            >
              ส่งคำเชิญ
            </Button>
          </Flex>
        </Form>
      </Drawer>

      <Drawer
        className="verify-access"
        title="เชิญสมาชิก"
        placement="right"
        maskClosable={false}
        width={472}
        onClose={() => setIsVerifyAccessOpen(false)}
        open={isVerifyAccessOpen}
      >
        <Alert
          description={`เราส่งรหัสยืนยันตัวตนไปที่อีเมล์ ${createAccessEmail} เรียบร้อยแล้ว โปรดกรอกรหัสยืนยันตัวตน 6 หลักที่เราส่งให้ รหัสจะหมดอายุภายใน 2 นาที`}
          type="info"
          showIcon
          style={{ marginBottom: 24 }}
        />
        <Form
          form={verifyAccessForm}
          layout="vertical"
          onFinish={onFinishVerifyAccess}
        >
          <Form.Item hidden name="accessId">
            <Input />
          </Form.Item>
          <Flex
            justify="space-between"
            align="center"
            style={{ lineHeight: '24px', marginBottom: 4 }}
          >
            <span className="font-size-body-2-bold">
              รหัสยืนยันตัวตน
              <span
                className="font-size-body-2-regular font-color-text-icon-detail"
                style={{ marginLeft: 8 }}
              >
                Ref: {accessVerificationRef}
              </span>
            </span>
            {isLoadingResendAccessCode ? (
              <Spin indicator={<LoadingOutlined spin />} />
            ) : (
              <span
                onClick={onClickResendCode}
                className={`font-size-text-link-2-regular font-color-text-icon-detail resend-btn ${
                  countTime > 0 ? 'disable' : ''
                }`}
              >
                ส่งรหัสอีกครั้ง
                {countTime > 0 ? (
                  <>
                    ภายใน 0{Math.floor(countTime / 60)}:
                    {(countTime % 60).toString().length === 1 ? '0' : ''}
                    {countTime % 60} นาที
                  </>
                ) : (
                  ''
                )}
              </span>
            )}
          </Flex>
          <Form.Item
            name="code"
            rules={[{ required: true, message: 'โปรดกรอกรหัสยืนยันตัวตน' }]}
          >
            <Input placeholder="กรอกรหัสยืนยันตัวตน" allowClear />
          </Form.Item>
          <Flex justify="end" style={{ marginTop: 40 }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoadingVerifyAccess}
            >
              เสร็จสิ้น
            </Button>
          </Flex>
        </Form>
      </Drawer>

      <Drawer
        title={`แก้ไข: ${editName}`}
        placement="right"
        maskClosable={false}
        width={472}
        onClose={() => setIsEditAccessOpen(false)}
        open={isEditAccessOpen}
      >
        <Form
          form={editAccessForm}
          layout="vertical"
          onFinish={onFinishEditAccess}
        >
          <Form.Item hidden name="accessId">
            <Input />
          </Form.Item>
          <Form.Item
            label="บทบาท"
            name="type"
            rules={[{ required: true, message: 'โปรดเลือกบทบาท' }]}
          >
            <Select placeholder="เลือกบทบาท" allowClear>
              <Option value={AccessType.CO_WORKER}>
                ผู้ช่วยหลัก (Co-worker)
              </Option>
              <Option value={AccessType.VIEWER}>ดูเท่านั้น (Viewer)</Option>
            </Select>
          </Form.Item>
          <Form.Item
            className="custom-form"
            label="ปิดการใช้งาน"
            name="deactivate"
          >
            <Switch />
          </Form.Item>
          <span className="font-size-caption-regular font-color-text-icon-detail">
            หากปิดการใช้งาน สมาชิกจะไม่สามารถเข้าถึง
            หรือไม่สามารถจัดการแผนธุรกิจ ได้ชั่วคราว
            คุณสามารถเปิด/ปิดการเข้าถึงได้ตลอดเวลา
          </span>
          <Flex justify="end" style={{ marginTop: 40 }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoadingEditAccess}
            >
              ยืนยันการแก้ไข
            </Button>
          </Flex>
        </Form>
      </Drawer>

      <Modal
        open={isDeleteModalOpen}
        maskClosable={false}
        closable={false}
        width={416}
        title={`คุณต้องการลบ ${deleteName} ใช่ไหม?`}
        className="modal-platform"
        footer={
          <Flex gap={8} justify="end" style={{ marginTop: 24 }}>
            <Button type="text" onClick={() => setIsDeleteModalOpen(false)}>
              ไว้ทีหลัง
            </Button>
            <Button
              type="primary"
              loading={isLoadingDelete}
              onClick={onClickDeleteAccess}
            >
              ลบ
            </Button>
          </Flex>
        }
      >
        สมาชิกจะถูกลบออกจากทีม คุณสามารถกลับเข้ามา ได้อีกอีกครั้ง ที่เมนูตั้งค่า
        “เชิญสมาชิก”
      </Modal>

      {contextHolder}
    </Flex>
  )
}

export default MemberPage
