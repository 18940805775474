export type PartnerIntegration = {
  partnerIntegrationId: string
  companyId: string
  userId: string
  platform: Platform
  shopId: string
  shopName: string
  shopLogo: string
  readyToSync: boolean
  createdAt: string
  updatedAt: string
}

export enum Platform {
  SHOPEE = 'shopee',
  LAZADA = 'lazada',
  TIKTOK = 'tiktok',
}

export enum PlatformField {
  PLATFORM = 'platform',
  CATEGORY = 'category',
  NAME = 'name',
}

export enum ShopeeField {
  PLATFORM = 'platform',
  CATEGORY = 'category',
  NAME = 'name',
}

export enum LazadaField {
  PLATFORM = 'platform',
  CATEGORY = 'category',
  NAME = 'name',
}

export enum TiktokField {
  PLATFORM = 'platform',
  CATEGORY = 'category',
  NAME = 'name',
}
