import './Profile.css'

import { Button, Flex, Form, FormProps, Input, message } from 'antd'
import { NewUserState, User } from '../../types'

import PictureProfile from '../../components/PictureProfile/PictureProfile'
import { updateUserInfo } from '../../services/user.service'
import { useState } from 'react'
import userStore from '../../stores/user.store'

type UserInfoForm = Pick<User, 'firstName' | 'lastName'>

const ProfilePage = () => {
  const { user, setUser } = userStore
  const { firstName, lastName, picture, email } = user

  const [userInfoForm] = Form.useForm()

  const [messageApi, contextHolder] = message.useMessage()

  const [isUpdateUserInfoLoading, setIsUpdateUserInfoLoading] = useState(false)

  const onUpdateUserInfo: FormProps<UserInfoForm>['onFinish'] = (
    userInfoForm: UserInfoForm,
  ) => {
    setIsUpdateUserInfoLoading(true)
    updateUserInfo({
      ...userInfoForm,
      picture: user.picture,
      newUserState: NewUserState.COMPLETE,
    }).then(() => {
      messageApi.open({
        type: 'success',
        content: 'แก้ไขเรียบร้อยแล้ว',
      })
      setUser({
        ...user,
        firstName: userInfoForm.firstName,
        lastName: userInfoForm.lastName,
      })
      setIsUpdateUserInfoLoading(false)
    })
  }

  return (
    <Flex className="profile-page" gap={16} vertical>
      <Flex align="center" justify="space-between">
        <span className="font-size-h2-bold font-color-text-icon-main">
          ตั้งค่าโปรไฟล์
        </span>
        <Button
          type="primary"
          loading={isUpdateUserInfoLoading}
          onClick={() => userInfoForm.submit()}
        >
          บันทึก
        </Button>
      </Flex>

      <Flex className="profile-info-block">
        <PictureProfile picture={picture} firstName={firstName} size={100} />
        <Form
          form={userInfoForm}
          layout="vertical"
          style={{ width: '100%', maxWidth: '520px' }}
          onFinish={onUpdateUserInfo}
        >
          <Form.Item
            label="ชื่อ"
            name="firstName"
            initialValue={firstName}
            rules={[{ required: true, message: 'โปรดกรอกชื่อ' }]}
            style={{ flex: 1 }}
          >
            <Input placeholder="กรอกชื่อ" allowClear />
          </Form.Item>
          <Form.Item
            label="นามสกุล"
            name="lastName"
            initialValue={lastName}
            rules={[{ required: true, message: 'โปรดกรอกนามสกุล' }]}
            style={{ flex: 1 }}
          >
            <Input placeholder="กรอกนามสกุล" allowClear />
          </Form.Item>
          <Form.Item label="อีเมล์" name="email" initialValue={email}>
            <Input disabled={true} allowClear />
          </Form.Item>
        </Form>
      </Flex>
      {contextHolder}
    </Flex>
  )
}

export default ProfilePage
