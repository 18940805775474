import './SearchBusinessTypeSegmented.css'

import { Dispatch, SetStateAction } from 'react'
import { Flex, Segmented } from 'antd'

import { SearchSegmentedTab } from '../../types'

const SearchBusinessTypeSegmented = ({
  currentTab,
  setCurrentTab,
  tabTitleLeft,
  tabTitleRight,
  tabLeftElement,
  tabRightElement,
}: {
  currentTab: SearchSegmentedTab
  setCurrentTab: Dispatch<SetStateAction<SearchSegmentedTab>>
  tabTitleLeft: string
  tabTitleRight: string
  tabLeftElement: JSX.Element
  tabRightElement: JSX.Element
}) => {
  const onChange = (currentTab: SearchSegmentedTab) => {
    setCurrentTab(currentTab)
  }

  return (
    <>
      <Segmented
        className="search-business-type-segmented-cmpt"
        options={[
          {
            label: (
              <Flex align="center" gap={8}>
                <div className="chat-gpt-icon" />
                <span className="font-size-caption-medium">{tabTitleLeft}</span>
              </Flex>
            ),
            value: SearchSegmentedTab.LEFT,
          },
          {
            label: (
              <Flex align="center">
                <span className="font-size-caption-medium">
                  {tabTitleRight}
                </span>
              </Flex>
            ),
            value: SearchSegmentedTab.RIGHT,
          },
        ]}
        value={currentTab}
        onChange={onChange}
      />
      {currentTab === SearchSegmentedTab.LEFT && tabLeftElement}
      {currentTab === SearchSegmentedTab.RIGHT && tabRightElement}
    </>
  )
}

export default SearchBusinessTypeSegmented
