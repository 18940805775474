import './UserPopover.css'

import {
  BookOutlined,
  LogoutOutlined,
  SettingOutlined,
} from '@ant-design/icons'
import { useLocation, useNavigate } from 'react-router-dom'

import { Flex } from 'antd'
import PictureProfile from '../PictureProfile/PictureProfile'
import { config } from '../../config'
import { navigateWithParams } from '../../utils/navigate.util'
import { useKeycloak } from '@react-keycloak/web'
import userStore from '../../stores/user.store'

const UserPopover = () => {
  const { profilePath, howToUsePath } = config.path

  const { user } = userStore
  const { firstName, lastName, picture } = user

  const { keycloak } = useKeycloak()

  const navigate = useNavigate()

  const { pathname } = useLocation()
  const pathnames = pathname.split('/')
  const rootPath = `/${pathnames[1]}`

  return (
    <Flex vertical gap={8} className="user-popover-cmpt">
      <Flex gap={8}>
        <PictureProfile picture={picture} firstName={firstName} size={40} />
        <Flex vertical>
          <span className="full-name font-size-body-2-medium font-color-text-icon-main">
            {firstName} {lastName}
          </span>
          <span className="role font-size-tooltip-regular font-color-text-icon-detail">
            เจ้าของ
          </span>
        </Flex>
      </Flex>
      <div className="line"></div>
      <Flex
        gap={8}
        className={`item font-size-body-2-regular font-color-text-icon-detail ${
          rootPath === profilePath ? 'selected' : ''
        }`}
        onClick={() => navigate(navigateWithParams(profilePath, pathnames))}
      >
        <SettingOutlined />
        <span>ตั้งค่า</span>
      </Flex>
      <Flex
        gap={8}
        className={`item font-size-body-2-regular font-color-text-icon-detail ${
          rootPath === howToUsePath ? 'selected' : ''
        }`}
        onClick={() => navigate(navigateWithParams(howToUsePath, pathnames))}
      >
        <BookOutlined />
        <span>คู่มือการใช้งาน</span>
      </Flex>
      <div className="line"></div>
      <Flex
        gap={8}
        className="item font-size-body-2-regular font-color-text-icon-detail"
        onClick={() => keycloak.logout()}
      >
        <LogoutOutlined />
        <span>ออกจากระบบ</span>
      </Flex>
    </Flex>
  )
}

export default UserPopover
