import { BusinessType, PlanTemplate } from '../types'

import { config } from '../config'

export function getTemplateByBusinessType(
  businessType: BusinessType,
): PlanTemplate {
  const { planTemplate } = config
  const index = Object.values(planTemplate).findIndex((values) => {
    return values.includes(businessType)
  })
  return index === -1
    ? PlanTemplate.GENERAL_BUSINESS
    : (Object.keys(planTemplate)[index] as PlanTemplate)
}
