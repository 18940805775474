export type Plan = {
  planId: string
  companyId: string
  userId: string
  name: string
  template: PlanTemplate
  startDate: string
  endDate: string
  createdAt: string
  updatedAt: string
}

export enum PlanTemplate {
  CONSTRUCTION_CONTRACTOR = 'construction-contractor',
  ONLINE_STORE = 'online-store',
  CLOTHING_STORE = 'clothing-store',
  COSMETIC_STORE = 'cosmetic-store',
  ELECTRICAL_STORE = 'electrical-store',
  PET_STORE = 'pet-store',
  PHARMACY = 'pharmacy',
  GENERAL_RESTAURANT = 'general-restaurant',
  BUFFET_RESTAURANT = 'buffet-restaurant',
  LIQUOR_STORE_OR_PUB = 'liquor-store-or-pub',
  CATERING = 'catering',
  CAFE = 'cafe',
  FRANCHISE_RESTAURANT = 'franchise-restaurant',
  HOUSE_CONDO_APARTMENT_OFFICE_FOR_RENT = 'house-condo-apartment-office-for-rent',
  REAL_ESTATE_AGENT = 'real-estate-agent',
  GENERAL_BUSINESS = 'general-business',
}
