import './NewUserModal.css'

import { Button, Flex, Modal } from 'antd'
import { Dispatch, SetStateAction } from 'react'

const NewUserModal = ({
  onSubmitModal,
  setIsModalOpen,
  width = 600,
  open,
  title,
  body,
  textBtn,
}: {
  onSubmitModal: () => void
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
  width?: number
  open: boolean
  title: string
  body: JSX.Element
  textBtn: string
}) => {
  const onCancel = () => {
    setIsModalOpen(false)
  }

  const onSubmit = () => {
    onSubmitModal()
    setIsModalOpen(false)
  }

  return (
    <Modal
      width={width}
      open={open}
      onCancel={onCancel}
      maskClosable={false}
      centered={true}
      className="new-user-modal"
      footer={
        <Button block type="primary" size="large" onClick={onSubmit}>
          {textBtn}
        </Button>
      }
    >
      <Flex vertical gap={24}>
        <span className="font-size-h3-bold font-color-primary-purple title">
          {title}
        </span>
        {body}
      </Flex>
    </Modal>
  )
}

export default NewUserModal
