import './WelcomeUser.css'

import { Flex } from 'antd'
import { Link } from 'react-router-dom'
import PictureProfile from '../PictureProfile/PictureProfile'
import userStore from '../../stores/user.store'

const WelcomeUser = ({
  form,
  description,
  topic,
  companyName = '',
  backStepUrl,
}: {
  form: JSX.Element
  description: string
  topic: string
  companyName?: string
  backStepUrl?: string
}) => {
  const { user } = userStore
  const { firstName, lastName, picture } = user

  return (
    <Flex className="welcome-user-cmpt">
      <Flex className="left-frame" vertical justify="space-between">
        <Flex vertical className="picture-welcome-name-description-flex">
          <Flex className="picture-welcome-name-flex">
            <PictureProfile picture={picture} firstName={firstName} size={72} />

            <Flex vertical>
              <span className="font-size-body-1-regular font-color-text-icon-main">
                ยินดีต้อนรับ 👋🏻
              </span>
              <span className="font-size-h3-regular font-color-text-icon-main">
                {firstName} {lastName}
              </span>
            </Flex>
          </Flex>
          <span className="font-size-body-1-regular font-color-text-icon-detail">
            {description}
          </span>
        </Flex>
        {backStepUrl && (
          <Link
            to={backStepUrl}
            className="font-size-text-link-2-regular back-step"
          >
            ย้อนกลับ
          </Link>
        )}
      </Flex>
      <Flex className="right-frame" vertical>
        <span
          className={`font-size-h2-bold font-color-primary-purple ${
            companyName ? '' : 'topic'
          }`}
        >
          {topic}
        </span>
        {companyName && (
          <span className="font-size-h4-regular font-color-text-icon-detail company">
            {companyName}
          </span>
        )}
        {form}
      </Flex>
    </Flex>
  )
}

export default WelcomeUser
