import { Flex, Tabs, TabsProps } from 'antd'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { config } from '../../config'
import { navigateWithParams } from '../../utils/navigate.util'
import { observer } from 'mobx-react'

const SettingRoute = observer(() => {
  const { memberPath, profilePath, settingPath } = config.path

  const navigate = useNavigate()
  const { pathname } = useLocation()
  const pathnames = pathname.split('/')
  const rootPath = `/${pathnames[1]}`

  const onChange = (key: string) => {
    navigate(navigateWithParams(key, pathnames))
  }

  const items: TabsProps['items'] = [
    {
      key: profilePath,
      label: 'ตั้งค่าโปรไฟล์',
    },
    {
      key: memberPath,
      label: 'สมาชิกในทีม',
    },
    {
      key: settingPath,
      label: 'บริษัท และแผนธุรกิจ',
    },
  ]

  return (
    <Flex gap={40} vertical style={{ height: '100%' }}>
      <Tabs activeKey={rootPath} items={items} onChange={onChange} />
      <Outlet />
    </Flex>
  )
})

export default SettingRoute
