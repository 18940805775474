import Keycloak from 'keycloak-js'
import { config } from '.'

const keycloakConfig = new Keycloak({
  url: config.keycloak.url,
  realm: config.keycloak.realm,
  clientId: config.keycloak.clientId,
})

export default keycloakConfig
