import './CreateCompany.css'

import {
  BusinessType,
  BusinessTypes,
  Company,
  Country,
  Currency,
  NewUserState,
  SearchSegmentedTab,
} from '../../types'
import { Button, Flex, Form, FormProps, Input, Select } from 'antd'
import { createCompany, updateCompany } from '../../services/company.service'
import { useEffect, useState } from 'react'

import { InfoCircleOutlined } from '@ant-design/icons'
import NewUserModal from '../../components/NewUserModal/NewUserModal'
import SearchBusinessTypeAdvice from '../../components/SearchBusinessTypeAdvice/SearchBusinessTypeAdvice'
import SearchBusinessTypeSegmented from '../../components/SearchBusinessTypeSegmented/SearchBusinessTypeSegmented'
import SearchBusinessTypeSelect from '../../components/SearchBusinessTypeSelect/SearchBusinessTypeSelect'
import { SearchOutlined } from '@ant-design/icons'
import WelcomeUser from '../../components/WelcomeUser/WelcomeUser'
import companiesStore from '../../stores/companies.store'
import { config } from '../../config'
import { updateUserInfo } from '../../services/user.service'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import userStore from '../../stores/user.store'

const { Option } = Select
type CompanyForm = Pick<
  Company,
  'name' | 'businessType' | 'country' | 'currency'
>

const CreateCompanyPage = () => {
  const { createPlanPath } = config.path

  const { user } = userStore
  const { companies, unshiftCompany } = companiesStore

  const navigate = useNavigate()
  const { t } = useTranslation()

  const [valueAi, setValueAi] = useState<BusinessType>()
  const [optionsAi, setOptionsAi] = useState<BusinessType[]>([])
  const [valueNormal, setValueNormal] = useState<BusinessType>()

  const [currentTab, setCurrentTab] = useState<SearchSegmentedTab>(
    SearchSegmentedTab.LEFT,
  )

  const [isModalOpen, setIsModalOpen] = useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const [companyForm] = Form.useForm()

  useEffect(() => {
    if (companies.length > 0) {
      const { name, businessType, country, currency } = companies[0]

      companyForm.setFieldsValue({ name, businessType, country, currency })

      setOptionsAi(BusinessTypes)
      setValueAi(businessType)
      setValueNormal(businessType)
    } else {
      companyForm.setFieldValue('country', Country.TH)
      companyForm.setFieldValue('currency', Currency.THB)
    }
  }, [companies, companyForm])

  const onFinish: FormProps<CompanyForm>['onFinish'] = (
    companyForm: CompanyForm,
  ) => {
    setIsLoading(true)

    if (companies.length > 0) {
      const { companyId } = companies[0]
      updateCompany({ companyId, ...companyForm }).then((responses) => {
        companiesStore.updateCompany(responses.data)
        navigate(createPlanPath)
      })
    } else {
      Promise.all([
        createCompany(companyForm),
        updateUserInfo({
          ...user,
          newUserState: NewUserState.CREATE_PLAN,
        }),
      ]).then((responses) => {
        unshiftCompany(responses[0].data)
        navigate(createPlanPath)
      })
    }
  }

  const onSubmitModal = () => {
    if (currentTab === SearchSegmentedTab.LEFT) {
      valueAi && companyForm.setFieldValue('businessType', valueAi)
    } else {
      valueNormal && companyForm.setFieldValue('businessType', valueNormal)
    }
    companyForm.validateFields(['businessType'])
  }

  const onOpenModal = () => {
    setIsModalOpen(true)
  }

  return (
    <Flex className="create-company-page">
      <WelcomeUser
        form={
          <Form form={companyForm} layout="vertical" onFinish={onFinish}>
            <Form.Item
              label="ชื่อบริษัท"
              name="name"
              rules={[{ required: true, message: 'โปรดกรอกชื่อบริษัท' }]}
            >
              <Input placeholder="กรอกชื่อบริษัท" allowClear />
            </Form.Item>
            <Form.Item
              label="ค้นหาประเภทธุรกิจหลัก"
              name="businessType"
              rules={[{ required: true, message: 'โปรดเลือกประเภทธุรกิจหลัก' }]}
              tooltip={{
                title:
                  'ประเภทธุรกิจ อ้างอิงตามการจัดประเภทมาตรฐาน\nอุตสาหกรรม (ประเทศไทย) ปี 2552\nของกรมการจัดหางาน กระทรวงแรงงาน',
                icon: <InfoCircleOutlined />,
              }}
            >
              <Select
                open={false}
                placeholder="พิมพ์คำค้นหา เช่น ขายข้าวมันไก่ แบบมีหน้าร้านและขายออนไลน์"
                filterOption={false}
                onClick={onOpenModal}
                suffixIcon={<SearchOutlined />}
                notFoundContent={null}
                options={BusinessTypes.map((businessType: string) => ({
                  label: t(businessType),
                  value: businessType,
                }))}
                allowClear
              />
            </Form.Item>
            <Form.Item
              label="ประเทศ"
              name="country"
              rules={[{ required: true, message: 'โปรดเลือกประเทศ' }]}
            >
              <Select placeholder="เลือกประเทศ" allowClear>
                <Option value={Country.TH}>🇹🇭 ไทย</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="สกุลเงิน"
              name="currency"
              rules={[{ required: true, message: 'โปรดเลือกสกุลเงิน' }]}
            >
              <Select placeholder="เลือกสกุลเงิน" allowClear>
                <Option value={Currency.THB}>ไทยบาท (฿)</Option>
              </Select>
            </Form.Item>
            <Button
              block
              type="primary"
              htmlType="submit"
              size="large"
              loading={isLoading}
            >
              ถัดไป
            </Button>
          </Form>
        }
        description={'ใส่ข้อมูลเกี่ยวกับบริษัทของคุณ เพื่อเริ่มต้นใช้งาน'}
        topic={'สร้างบริษัท'}
      />
      <NewUserModal
        title="ค้นหาประเภทธุรกิจหลัก"
        textBtn="ยืนยัน"
        open={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        onSubmitModal={onSubmitModal}
        body={
          <SearchBusinessTypeSegmented
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            tabTitleLeft="ค้นหาด้วย AI"
            tabTitleRight="ค้นหาจากรายการ"
            tabLeftElement={
              <>
                <SearchBusinessTypeSelect
                  value={valueAi}
                  setValue={setValueAi}
                  options={optionsAi}
                  setOptions={setOptionsAi}
                  placeholder="พิมพ์คำค้นหา เช่น ขายข้าวมันไก่ แบบมีหน้าร้านและขายออนไลน์"
                  notFoundTitle1="พิมพ์คำค้นหาใหม่"
                  notFoundTitle2="โปรดพิมพ์อธิบายเกี่ยวกับธุรกิจของคุณให้ชัดเจนขึ้น"
                />
                <SearchBusinessTypeAdvice
                  isHide={!!valueAi}
                  description1="พิมพ์อธิบายเกี่ยวกับธุรกิจของคุณ"
                  description2="เพื่อให้ AI เราช่วยแนะนำ"
                />
              </>
            }
            tabRightElement={
              <>
                <SearchBusinessTypeSelect
                  value={valueNormal}
                  setValue={setValueNormal}
                  options={BusinessTypes}
                  setOptions={() => {}}
                  filterOption={true}
                  optionFilterProp="label"
                  placeholder="พิมพ์คำค้นหา หรือเลือกจากรายการ"
                  notFoundTitle1="พิมพ์คำค้นหาใหม่"
                  notFoundTitle2="โปรดพิมพ์คำค้นหาใหม่อีกครั้ง"
                />
                <SearchBusinessTypeAdvice
                  isHide={!!valueNormal}
                  description1="พิมพ์คำค้น หรือเลือกประเภทธุรกิจ"
                  description2="จากรายการที่เราเตรียมไว้ให้"
                />
              </>
            }
          />
        }
      />
    </Flex>
  )
}

export default CreateCompanyPage
