import { Company } from '../types'
import { makeAutoObservable } from 'mobx'

class CompaniesStore {
  companies: Company[] = []

  constructor() {
    makeAutoObservable(this)
  }

  setCompanies = (companies: Company[]) => {
    this.companies = companies
  }

  unshiftCompany = (newCompany: Company) => {
    this.companies.unshift(newCompany)
  }

  removeCompany = (companyId: string) => {
    const deletedIndex = this.companies.findIndex(
      (company) => company.companyId === companyId,
    )
    if (deletedIndex !== -1) {
      this.companies.splice(deletedIndex, 1)
    }
  }

  updateCompany = (updatedCompany: Company) => {
    const index = this.companies.findIndex(
      (company) => company.companyId === updatedCompany.companyId,
    )
    if (index !== -1) {
      this.companies[index] = updatedCompany
    }
  }
}
const companiesStore = new CompaniesStore()
export default companiesStore
