import './Platform.css'

import {
  Avatar,
  Button,
  Drawer,
  Flex,
  Form,
  FormProps,
  Input,
  Modal,
  Popover,
  Table,
  message,
} from 'antd'
import {
  DeleteOutlined,
  EditOutlined,
  MoreOutlined,
  PlusOutlined,
  ShopOutlined,
} from '@ant-design/icons'
import {
  Link,
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import {
  PartnerIntegration,
  Platform,
} from '../../types/partner-integration.type'
import {
  createPartnerIntegration,
  deletePartnerIntegration,
  getAllPartnerIntegrationsByCompany,
  getUriConnection,
  updatePartnerIntegration,
} from '../../services/partner-integration.service'
import { useEffect, useRef, useState } from 'react'

import { AxiosError } from 'axios'
import LoadingBrandColor from '../../components/LoadingBrandColor/LoadingBrandColor'
import { config } from '../../config'
import { upperFirst } from 'lodash'

type PartnerIntegrationForm = { partnerIntegrationId: string; shopName: string }

const PlatformPage = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const searchParamPlatform = searchParams.get('platform') as Platform
  const { companyId: currentCompanyId } = useParams()
  const { howToUsePath, settingPath } = config.path

  const [isLoading, setIsLoading] = useState(true)
  const [partnerIntegrations, setPartnerIntegrations] = useState<
    PartnerIntegration[]
  >([])

  const platformConnection = useRef({})
  const [isLoadingConnect, setIsLoadingConnect] = useState(false)

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [deleteId, setDeleteId] = useState('')
  const [deleteShopName, setDeleteShopName] = useState('')
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)

  const [messageApi, contextHolder] = message.useMessage()

  const [isChangeShopNameOpen, setIsChangeShopNameOpen] = useState(false)
  const [isLoadingChangeShopName, setIsLoadingChangeShopName] = useState(false)
  const [partnerIntegrationForm] = Form.useForm()

  const platformLabel: { [key: string]: string } = {
    [Platform.SHOPEE]: 'Shopee',
    [Platform.LAZADA]: 'Lazada',
    [Platform.TIKTOK]: 'TikTok Shop',
  }

  useEffect(() => {
    if (!Object.values(Platform).includes(searchParamPlatform)) {
      navigate({
        search: createSearchParams({}).toString(),
      })
    }
  }, [navigate, searchParamPlatform])

  useEffect(() => {
    setIsLoading(true)
    if (currentCompanyId) {
      getAllPartnerIntegrationsByCompany(currentCompanyId)
        .then((response) => {
          setPartnerIntegrations(response.data)
        })
        .finally(() => setIsLoading(false))
    } else {
      setIsLoading(false)
    }
  }, [currentCompanyId])

  const onClickConnect = async (platform: Platform) => {
    const popupWinWidth = 1200
    const popupWinHeight = 600
    const left = (window.screen.width - popupWinWidth) / 2
    const top = (window.screen.height - popupWinHeight) / 4

    const connectWindow = window.open(
      '',
      'Connect platform',
      'resizable=yes, width=' +
        popupWinWidth +
        ', height=' +
        popupWinHeight +
        ', top=' +
        top +
        ', left=' +
        left,
    )

    const state = (Math.random() + 1).toString(36).substring(7)
    const response = await getUriConnection(platform, state)
    const { uri } = response.data

    localStorage.setItem('connection-state', state)
    platformConnection.current = platform

    connectWindow!.location.href = uri

    window.removeEventListener('message', onReceiveMessage, false)
    window.addEventListener('message', onReceiveMessage, false)
  }

  const onReceiveMessage = (
    event: MessageEvent<{ status: string; shopId: string; state: string }>,
  ) => {
    if (window.location.origin === event.origin) {
      if (event.data.status === '1') {
        setIsLoadingConnect(true)
        createPartnerIntegration({
          shopId: event.data.shopId,
          companyId: currentCompanyId!,
          platform: platformConnection.current as Platform,
        })
          .then((response) => {
            partnerIntegrations.unshift(response.data)
            messageApi.open({
              type: 'success',
              content: 'เชื่อมต่อเรียบร้อยแล้ว',
            })
          })
          .catch((error: AxiosError) => {
            if (error.response?.status === 409) {
              messageApi.open({
                type: 'warning',
                content: 'มีการเชื่อมต่อร้านนี้แล้ว ไม่สามารถเชื่อมต่อซ้ำได้',
              })
            }
          })
          .finally(() => setIsLoadingConnect(false))
      } else {
        messageApi.open({
          type: 'error',
          content: 'เชื่อมต่อไม่สำเร็จ โปรดลองเชื่อมต่อใหม่อีกครั้ง',
        })
      }
      window.removeEventListener('message', onReceiveMessage, false)
    }
  }

  const onClickOpenDeleteModal = (
    partnerIntegrationId: string,
    shopName: string,
  ) => {
    setIsDeleteModalOpen(true)
    setDeleteId(partnerIntegrationId)
    setDeleteShopName(shopName)
  }

  const onClickDeletePartnerIntegration = () => {
    setIsLoadingDelete(true)
    deletePartnerIntegration(deleteId)
      .then(() => {
        const deletedIndex = partnerIntegrations.findIndex(
          (partnerIntegration) =>
            partnerIntegration.partnerIntegrationId === deleteId,
        )
        if (deletedIndex !== -1) {
          partnerIntegrations.splice(deletedIndex, 1)
        }
        messageApi.open({
          type: 'success',
          content: 'ลบเรียบร้อยแล้ว',
        })
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 422) {
          Modal.warning({
            title: 'คุณยังไม่สามารถลบได้ในตอนนี้',
            content: `เนื่องจากคุณใช้ข้อมูลของ ${deleteShopName} ทำรายงานการเงินอยู่ในตอนนี้
              หากคุณต้องการลบ โปรดเข้าไปแก้ไขแผนธุรกิจของคุณ ที่เมนูการตั้งค่า`,
            okText: 'ตกลง',
          })
        }
      })
      .finally(() => {
        setIsLoadingDelete(false)
        setIsDeleteModalOpen(false)
      })
  }

  const onFinish: FormProps<PartnerIntegrationForm>['onFinish'] = (
    partnerIntegrationForm: PartnerIntegrationForm,
  ) => {
    const { partnerIntegrationId, shopName } = partnerIntegrationForm
    setIsLoadingChangeShopName(true)
    updatePartnerIntegration(partnerIntegrationId, shopName)
      .then((response) => {
        const { partnerIntegrationId, shopName } = response.data
        const partnerIntegration = partnerIntegrations.find(
          (partnerIntegration) =>
            partnerIntegration.partnerIntegrationId === partnerIntegrationId,
        )
        if (partnerIntegration) partnerIntegration.shopName = shopName
        messageApi.open({
          type: 'success',
          content: 'แก้ไขชื่อร้านเรียบร้อยแล้ว',
        })
      })
      .catch(() => {
        messageApi.open({
          type: 'error',
          content: 'แก้ไขชื่อร้านไม่สำเร็จ โปรดลองใหม่อีกครั้ง',
        })
      })
      .finally(() => {
        setIsChangeShopNameOpen(false)
        setIsLoadingChangeShopName(false)
      })
  }

  if (isLoading) {
    return <LoadingBrandColor />
  }

  return (
    <Flex className="platform-page" vertical>
      {!searchParamPlatform ? (
        <>
          <span className="font-size-h2-bold font-color-text-icon-main">
            แพลตฟอร์ม
          </span>
          <Flex className="description">
            <span className="font-size-body-1-regular font-color-text-icon-main">
              เชื่อมต่อ FP&A กับทุกแพลตฟอร์ม
              เพื่อความสะดวกในการนำเข้าข้อมูลแบบอัตโนมัติ
            </span>
            <Link
              to={howToUsePath}
              className="font-size-text-link-1-regular font-color-primary-purple help-link"
            >
              ดูวิธีการเชื่อมต่อแพลตฟอร์ม
            </Link>
          </Flex>

          {!currentCompanyId ? (
            <Flex
              align="center"
              vertical
              style={{ margin: '102px 0px 40px 0px' }}
            >
              <div className="no-data-icon" />
              <span
                className="font-size-body-2-bold font-color-text-icon-main"
                style={{ lineHeight: '24px' }}
              >
                ยังไม่มีบริษัท
              </span>
              <span
                className="font-size-body-2-regular font-color-text-icon-detail"
                style={{ lineHeight: '24px' }}
              >
                เพิ่มบริษัท เพื่อเริ่มต้นใช้งาน FP&A
              </span>
              <Button
                type="primary"
                size="small"
                icon={<PlusOutlined />}
                style={{ marginTop: 16 }}
                onClick={() => navigate(settingPath)}
              >
                เพิ่มบริษัท
              </Button>
            </Flex>
          ) : (
            <div className="group-card">
              {Object.values(Platform).map((platform, key) => (
                <Flex className="platform-card" key={key}>
                  <div className={`platform-logo ${platform}`} />

                  {partnerIntegrations.some(
                    (partnerIntegration) =>
                      partnerIntegration.platform === platform,
                  ) ? (
                    <>
                      <Flex gap={8} vertical>
                        {partnerIntegrations
                          .filter(
                            (partnerIntegration) =>
                              partnerIntegration.platform === platform,
                          )
                          .slice(0, 2)
                          .map((partnerIntegration, key) => (
                            <Flex
                              key={key}
                              gap={8}
                              align="center"
                              style={{
                                padding: '15px 16px',
                                borderRadius: 16,
                                border: '1px solid var(--stroke-gray)',
                              }}
                            >
                              <Avatar
                                size={40}
                                style={{
                                  border: '1px solid var(--stroke-gray)',
                                }}
                                icon={<ShopOutlined />}
                              />
                              <Flex
                                vertical
                                style={{
                                  width: 'calc(100% - 148px)',
                                }}
                              >
                                <span className="font-size-body-2-medium font-color-text-icon-main text-ellipsis">
                                  {partnerIntegration.shopName}
                                </span>
                                <span className="font-size-tooltip-regular font-color-text-icon-detail text-ellipsis">
                                  ID: {partnerIntegration.shopId}
                                </span>
                              </Flex>
                              <span className="status-connected font-size-tooltip-regular">
                                เชื่อมต่อแล้ว
                              </span>
                              <Popover
                                placement="bottomRight"
                                arrow={false}
                                content={
                                  <div className="shop-popover-cmpt">
                                    <Flex
                                      gap={8}
                                      className="item font-size-body-2-regular font-color-text-icon-detail"
                                      onClick={() => {
                                        partnerIntegrationForm.setFieldsValue({
                                          partnerIntegrationId:
                                            partnerIntegration.partnerIntegrationId,
                                          shopName: partnerIntegration.shopName,
                                        })
                                        setIsChangeShopNameOpen(true)
                                      }}
                                    >
                                      <EditOutlined />
                                      <span>แก้ไข</span>
                                    </Flex>
                                    <Flex
                                      gap={8}
                                      className="item font-size-body-2-regular font-color-text-icon-detail"
                                      onClick={() =>
                                        onClickOpenDeleteModal(
                                          partnerIntegration.partnerIntegrationId,
                                          partnerIntegration.shopName,
                                        )
                                      }
                                    >
                                      <DeleteOutlined />
                                      <span>ลบ</span>
                                    </Flex>
                                  </div>
                                }
                              >
                                <MoreOutlined className="font-color-text-icon-caption" />
                              </Popover>
                            </Flex>
                          ))}
                      </Flex>

                      <Flex style={{ marginTop: 'auto' }}>
                        <Button
                          type="primary"
                          size="small"
                          style={{ width: 'fit-content' }}
                          loading={
                            isLoadingConnect &&
                            platformConnection.current === platform
                          }
                          icon={<PlusOutlined />}
                          onClick={() => onClickConnect(platform)}
                        >
                          เพิ่ม
                        </Button>
                        <Button
                          type="text"
                          size="small"
                          onClick={() => {
                            navigate({
                              search: createSearchParams({
                                platform,
                              }).toString(),
                            })
                          }}
                          style={{ width: 'fit-content' }}
                        >
                          ดูทั้งหมด
                        </Button>
                      </Flex>
                    </>
                  ) : (
                    <>
                      <Flex align="center" vertical>
                        <div className="not-connect-icon" />
                        <span className="font-size-body-2-bold font-color-text-icon-main">
                          ยังไม่ได้เชื่อมต่อ
                        </span>
                        <span className="font-size-body-2-regular font-color-text-icon-detail">
                          เชื่อมต่อ FP&A กับทุกร้านค้าของคุณบน{' '}
                          {platformLabel[platform]}
                        </span>
                        <span className="font-size-body-2-regular font-color-text-icon-detail">
                          เพื่อนำข้อมูลการขายของคุณ มาทำรายงานการเงิน
                        </span>
                      </Flex>
                      <Button
                        type="primary"
                        size="small"
                        style={{ width: 'fit-content', alignSelf: 'center' }}
                        loading={
                          isLoadingConnect &&
                          platformConnection.current === platform
                        }
                        onClick={() => onClickConnect(platform)}
                      >
                        เชื่อมต่อ
                      </Button>
                    </>
                  )}
                </Flex>
              ))}
            </div>
          )}
        </>
      ) : (
        <Flex
          gap={16}
          vertical
          style={{
            height: '-webkit-fill-available',
          }}
        >
          <Flex gap={8}>
            <span
              className="platform-btn"
              onClick={() =>
                navigate({
                  search: createSearchParams({}).toString(),
                })
              }
            >
              แพลตฟอร์ม
            </span>
            <span className="font-size-caption-regular font-color-text-icon-detail">
              /
            </span>
            <span className="font-size-caption-regular font-color-primary-purple">
              {upperFirst(searchParamPlatform)}
            </span>
          </Flex>
          <Flex justify="space-between" align="center">
            <span className="font-size-h3-bold font-color-text-icon-main">
              {upperFirst(searchParamPlatform)} ทั้งหมด
            </span>
            <Button
              type="primary"
              loading={
                isLoadingConnect &&
                platformConnection.current === searchParamPlatform
              }
              icon={<PlusOutlined />}
              onClick={() => onClickConnect(searchParamPlatform)}
            >
              เพิ่ม
            </Button>
          </Flex>
          <Flex vertical className="all-shop">
            <div className={`platform-logo ${searchParamPlatform}`} />
            <Table
              scroll={{ x: 700 }}
              className="shop-table"
              pagination={false}
              locale={{
                emptyText: (
                  <Flex
                    align="center"
                    vertical
                    style={{ margin: '16px 0px 40px 0px' }}
                  >
                    <div className="no-data-icon" />
                    <span
                      className="font-size-body-2-bold font-color-text-icon-main"
                      style={{ lineHeight: '24px' }}
                    >
                      ยังไม่มีร้านค้า
                    </span>
                    <span
                      className="font-size-body-2-regular font-color-text-icon-detail"
                      style={{ lineHeight: '24px' }}
                    >
                      เชื่อมต่อ FP&A กับทุกร้านค้าของคุณบน{' '}
                      {platformLabel[searchParamPlatform]}
                    </span>
                    <span
                      className="font-size-body-2-regular font-color-text-icon-detail"
                      style={{ lineHeight: '24px' }}
                    >
                      เพื่อนำข้อมูลการขายของคุณ มาทำรายงานการเงิน
                    </span>
                  </Flex>
                ),
              }}
              columns={[
                {
                  title: 'ชื่อร้าน',
                  width: '50%',
                  ellipsis: true,
                  className: 'first-column',
                  render: (partnerIntegration: PartnerIntegration) => (
                    <Flex gap={8}>
                      <Avatar
                        size={40}
                        style={{
                          border: '1px solid var(--stroke-gray)',
                        }}
                        icon={<ShopOutlined />}
                      />
                      <Flex
                        vertical
                        style={{
                          width: 'calc(100% - 48px)',
                        }}
                      >
                        <span
                          className="font-size-body-2-medium font-color-text-icon-main"
                          style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            lineHeight: '24px',
                          }}
                        >
                          {partnerIntegration.shopName}
                        </span>
                        <span
                          className="font-size-tooltip-regular font-color-text-icon-detail"
                          style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            lineHeight: '19px',
                          }}
                        >
                          ID: {partnerIntegration.shopId}
                        </span>
                      </Flex>
                    </Flex>
                  ),
                },
                {
                  title: 'สถานะ',
                  width: '22%',
                  render: () => (
                    <span className="status-connected font-size-tooltip-regular">
                      เชื่อมต่อแล้ว
                    </span>
                  ),
                },
                {
                  title: 'วันที่เชื่อมต่อ',
                  width: '25%',
                  dataIndex: 'createdAt',
                  render: (createdAt) => (
                    <span className="font-size-body-2-regular font-color-text-icon-main">
                      {new Date(createdAt).toLocaleDateString('th-TH', {
                        year: 'numeric',
                        month: 'narrow',
                        day: 'numeric',
                      })}
                    </span>
                  ),
                },
                {
                  title: 'การจัดการ',
                  width: 100,
                  align: 'end',
                  className: 'last-column',
                  render: (partnerIntegration: PartnerIntegration) => (
                    <Flex gap={24} className="icon-management">
                      <EditOutlined
                        onClick={() => {
                          partnerIntegrationForm.setFieldsValue({
                            partnerIntegrationId:
                              partnerIntegration.partnerIntegrationId,
                            shopName: partnerIntegration.shopName,
                          })
                          setIsChangeShopNameOpen(true)
                        }}
                      />
                      <DeleteOutlined
                        onClick={() =>
                          onClickOpenDeleteModal(
                            partnerIntegration.partnerIntegrationId,
                            partnerIntegration.shopName,
                          )
                        }
                      />
                    </Flex>
                  ),
                },
              ]}
              dataSource={partnerIntegrations
                .filter(
                  (partnerIntegration) =>
                    partnerIntegration.platform === searchParamPlatform,
                )
                .map((partnerIntegration, key) => ({
                  ...partnerIntegration,
                  key,
                }))}
            />
          </Flex>
        </Flex>
      )}

      <Modal
        open={isDeleteModalOpen}
        maskClosable={false}
        closable={false}
        width={416}
        title={`คุณต้องการลบ ${deleteShopName} ใช่ไหม?`}
        className="modal-platform"
        footer={
          <Flex gap={8} justify="end" style={{ marginTop: 24 }}>
            <Button type="text" onClick={() => setIsDeleteModalOpen(false)}>
              ไว้ทีหลัง
            </Button>
            <Button
              type="primary"
              loading={isLoadingDelete}
              onClick={onClickDeletePartnerIntegration}
            >
              ลบ
            </Button>
          </Flex>
        }
      >
        หากคุณต้องการเชื่อมต่ออีกครั้ง สามารถกลับเข้ามา เชื่อมต่อได้อีกอีกครั้ง
        ที่เมนู “แพลตฟอร์ม”
      </Modal>

      <Drawer
        title="แก้ไขชื่อร้าน"
        placement="right"
        maskClosable={false}
        width={472}
        onClose={() => setIsChangeShopNameOpen(false)}
        open={isChangeShopNameOpen}
      >
        <Form
          form={partnerIntegrationForm}
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.Item hidden name="partnerIntegrationId">
            <Input />
          </Form.Item>
          <Form.Item
            label="ชื่อร้าน"
            name="shopName"
            rules={[{ required: true, message: 'โปรดกรอกชื่อร้าน' }]}
          >
            <Input placeholder="กรอกชื่อร้าน" allowClear />
          </Form.Item>
          <Flex justify="end" style={{ marginTop: 40 }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoadingChangeShopName}
            >
              ยืนยันการแก้ไข
            </Button>
          </Flex>
        </Form>
      </Drawer>

      {contextHolder}
    </Flex>
  )
}

export default PlatformPage
