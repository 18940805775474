import './CompanyAndPlanPopover.css'

import { Button, Flex } from 'antd'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import { config } from '../../config'

const CompanyAndPlanPopover = ({
  isCompany = true,
  list = [],
  currentId,
  btnText,
  addFrame = false,
  onClickOptional,
}: {
  isCompany?: boolean
  list: { id: string; name: string }[]
  currentId: string
  btnText: string
  addFrame?: boolean
  onClickOptional?: () => void
}) => {
  const { platformPath, settingPath } = config.path

  const navigate = useNavigate()
  const { pathname } = useLocation()
  const pathnames = pathname.split('/')
  const rootPath = pathnames[1]

  const [searchParams] = useSearchParams()
  const searchParamPlatform = searchParams.get('platform')
  const searchParamCompanyId = searchParams.get('company-id')

  const onClick = (id: string) => {
    if (isCompany) {
      navigateWithQuery(`/${id}`)
    } else {
      const companyId = pathnames[2]
      navigateWithQuery(`/${companyId}/${id}`)
    }

    onClickOptional && onClickOptional()
  }

  const navigateWithQuery = (path = '') => {
    if (`/${rootPath}` === platformPath && searchParamPlatform) {
      navigate(rootPath + path + `?platform=${searchParamPlatform}`)
    } else if (`/${rootPath}` === settingPath && searchParamCompanyId) {
      navigate(rootPath + path + `?company-id=${searchParamCompanyId}`)
    } else {
      navigate(rootPath + path)
    }
  }

  return (
    <Flex
      vertical
      gap={8}
      className={`company-and-plan-popover-cmpt ${addFrame ? 'frame' : ''}`}
    >
      {list.map((item, index) => (
        <Flex
          key={index}
          className={`item font-size-body-2-regular font-color-text-icon-detail ${
            item.id === currentId && 'selected'
          }`}
          onClick={() => onClick(item.id)}
          id={item.id}
        >
          <span>{item.name}</span>
          {item.id === currentId && <div className="tag">ปัจจุบัน</div>}
        </Flex>
      ))}
      <Button type="primary" size="small" onClick={() => navigate(settingPath)}>
        {btnText}
      </Button>
    </Flex>
  )
}

export default CompanyAndPlanPopover
