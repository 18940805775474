import axios, { AxiosResponse } from 'axios'

import { User } from '../types'

export const getUserInfo = async (): Promise<AxiosResponse<User>> => {
  return axios.get('v1/user')
}

export const updateUserInfo = async (
  data: Omit<User, 'email'>,
): Promise<AxiosResponse<void>> => {
  return axios.put('v1/user', data)
}
