import './PictureProfile.css'

import { Avatar } from 'antd'
import { UserOutlined } from '@ant-design/icons'

const PictureProfile = ({
  picture,
  firstName,
  size,
  isHover = false,
  ...props
}: {
  picture: string
  firstName: string
  size: number
  isHover?: boolean
}) => {
  return (
    <div {...props} style={{ width: size, height: size }}>
      {picture ? (
        <img
          className={`picture-profile-cmpt-img ${isHover ? 'hover' : ''}`}
          src={picture}
          alt="User profile"
          style={{ width: size - 4, height: size - 4 }}
        />
      ) : firstName ? (
        <Avatar
          className={`picture-profile-cmpt-avatar ${isHover ? 'hover' : ''}`}
          style={{ width: size, height: size, fontSize: size - 10 }}
        >
          {firstName[0].toLowerCase()}
        </Avatar>
      ) : (
        <Avatar
          className={`picture-profile-cmpt-avatar ${isHover ? 'hover' : ''}`}
          style={{ width: size, height: size, fontSize: size - 18 }}
          icon={<UserOutlined />}
        />
      )}
    </div>
  )
}

export default PictureProfile
