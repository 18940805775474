import './Sidebar.css'

import {
  BookOutlined,
  DashboardOutlined,
  GlobalOutlined,
  LeftOutlined,
  LineChartOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  RightOutlined,
  SettingOutlined,
  ShopOutlined,
  UserAddOutlined,
} from '@ant-design/icons'
import { Drawer, Flex, Menu } from 'antd'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import CompanyAndPlanPopover from '../CompanyAndPlanPopover/CompanyAndPlanPopover'
import PictureProfile from '../PictureProfile/PictureProfile'
import companiesStore from '../../stores/companies.store'
import { config } from '../../config'
import { navigateWithParams } from '../../utils/navigate.util'
import { observer } from 'mobx-react'
import plansStore from '../../stores/plans.store'
import sidebarStore from '../../stores/sidebar.store'
import { useKeycloak } from '@react-keycloak/web'
import { useTranslation } from 'react-i18next'
import userStore from '../../stores/user.store'

const Sidebar = observer(() => {
  const {
    reportPath,
    dashboardPath,
    platformPath,
    howToUsePath,
    memberPath,
    profilePath,
  } = config.path

  const {
    mobileSidebarCollapsed,
    collapseMobileSidebar,
    sidebarShrank,
    shrinkSidebar,
    unShrinkSidebar,
  } = sidebarStore
  const { companies } = companiesStore
  const { plans } = plansStore
  const { user } = userStore
  const { firstName, lastName, picture } = user

  const [mobileSidebarProfileCollapsed, setMobileSidebarProfileCollapsed] =
    useState(true)
  const [mobileSidebarCompanyCollapsed, setMobileSidebarCompanyCollapsed] =
    useState(true)
  const [mobileSidebarPlanCollapsed, setMobileSidebarPlanCollapsed] =
    useState(true)
  const [mobileSidebarLanguageCollapsed, setMobileSidebarLanguageCollapsed] =
    useState(true)

  const { keycloak } = useKeycloak()
  const { i18n } = useTranslation()
  const navigate = useNavigate()
  const { companyId: currentCompanyId, planId: currentPlanId } = useParams()
  const { pathname } = useLocation()
  const pathnames = pathname.split('/')
  const rootPath = `/${pathnames[1]}`

  useEffect(() => {
    if (i18n.language.includes('en')) {
      i18n.changeLanguage('en')
    } else {
      i18n.changeLanguage('th')
    }
  }, [i18n])

  const onClickNavigate = ({ key }: { key: string }) => {
    navigate(navigateWithParams(key, pathnames))
  }

  const onClickChangeLanguage = ({ key }: { key: string }) => {
    i18n.changeLanguage(key)
  }

  const onClickSubProfile = () => {
    setMobileSidebarProfileCollapsed(false)
  }

  const onClickCloseSubProfile = () => {
    setMobileSidebarProfileCollapsed(true)
  }

  const onClickSubCompany = () => {
    setMobileSidebarCompanyCollapsed(false)
  }

  const onClickCloseSubCompany = () => {
    setMobileSidebarCompanyCollapsed(true)
  }

  const onClickSubPlan = () => {
    setMobileSidebarPlanCollapsed(false)
  }

  const onClickCloseSubPlan = () => {
    setMobileSidebarPlanCollapsed(true)
  }

  const onClickSubLanguage = () => {
    setMobileSidebarLanguageCollapsed(false)
  }

  const onClickCloseSubLanguage = () => {
    setMobileSidebarLanguageCollapsed(true)
  }

  return (
    <>
      <div className="sidebar-cmpt">
        <div className="collapse-icon">
          {sidebarShrank ? (
            <MenuUnfoldOutlined onClick={unShrinkSidebar} />
          ) : (
            <MenuFoldOutlined onClick={shrinkSidebar} />
          )}
        </div>
        <Menu
          className="menu"
          selectedKeys={[rootPath]}
          mode="vertical"
          inlineCollapsed={sidebarShrank}
          items={[
            {
              key: '1',
              label: 'เมนู',
              type: 'group',
              className: 'first-title',
              children: [
                {
                  key: reportPath,
                  icon: <LineChartOutlined />,
                  label: 'ตารางการเงิน',
                  onClick: onClickNavigate,
                },
                {
                  key: dashboardPath,
                  icon: <DashboardOutlined />,
                  label: 'แดชบอร์ด',
                  onClick: onClickNavigate,
                },
                {
                  key: platformPath,
                  icon: <ShopOutlined />,
                  label: 'แพลตฟอร์ม',
                  onClick: onClickNavigate,
                },
              ],
            },
            {
              key: '2',
              label: 'ตัวช่วย',
              type: 'group',
              children: [
                {
                  key: howToUsePath,
                  icon: <BookOutlined />,
                  label: 'แนะนำการใช้งาน',
                  onClick: onClickNavigate,
                },
                {
                  key: memberPath,
                  icon: <UserAddOutlined />,
                  label: 'สมาชิกในทีม',
                  onClick: onClickNavigate,
                },
                {
                  key: profilePath,
                  icon: <SettingOutlined />,
                  label: 'ตั้งค่า',
                  onClick: onClickNavigate,
                },
              ],
            },
            {
              key: '3',
              icon: <GlobalOutlined />,
              label: i18n.language.toUpperCase(),
              children: [
                {
                  key: 'th',
                  label: 'TH',
                  className: i18n.language === 'th' ? 'selected' : '',
                  onClick: onClickChangeLanguage,
                },
                {
                  key: 'en',
                  label: 'EN',
                  className: i18n.language === 'en' ? 'selected' : '',
                  onClick: onClickChangeLanguage,
                },
              ],
            },
          ]}
        />
      </div>

      <Drawer placement="left" closable={false} open={!mobileSidebarCollapsed}>
        <Flex vertical>
          <Flex
            gap={8}
            vertical
            style={{
              padding: 16,
            }}
          >
            <Flex className="mobile-current-info" onClick={onClickSubProfile}>
              <PictureProfile
                picture={picture}
                firstName={firstName}
                size={40}
              />
              <Flex vertical>
                <span
                  className="font-size-body-2-medium font-color-text-icon-main"
                  style={{
                    lineHeight: '24px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: 271,
                  }}
                >
                  {firstName} {lastName}
                </span>
                <span
                  className="font-size-tooltip-regular font-color-text-icon-detail"
                  style={{ lineHeight: '19px' }}
                >
                  เจ้าของ
                </span>
              </Flex>
              <RightOutlined />
            </Flex>

            <div className="line"></div>

            <Flex className="mobile-current-info" onClick={onClickSubCompany}>
              <span className="font-size-body-2-regular font-color-text-icon-detail">
                บริษัท:
              </span>
              <span
                className="font-size-body-2-regular font-color-text-icon-main"
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: 275,
                }}
              >
                {currentCompanyId
                  ? companies.find(
                      (company) => company.companyId === currentCompanyId,
                    )?.name
                  : 'ไม่มีบริษัท'}
              </span>
              <RightOutlined />
            </Flex>

            <div className="line"></div>

            <Flex className="mobile-current-info" onClick={onClickSubPlan}>
              <span className="font-size-body-2-regular font-color-text-icon-detail">
                แผนธุรกิจ:
              </span>
              <span
                className="font-size-body-2-regular font-color-text-icon-main"
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: 254,
                }}
              >
                {currentPlanId
                  ? plans.find((plan) => plan.planId === currentPlanId)?.name
                  : 'ไม่มีแผน'}
              </span>
              <RightOutlined />
            </Flex>

            <div className="line"></div>
          </Flex>

          <Menu
            className="menu mobile"
            selectedKeys={[rootPath]}
            mode="vertical"
            inlineCollapsed={sidebarShrank}
            onClick={collapseMobileSidebar}
            items={[
              {
                key: '1',
                label: 'เมนู',
                type: 'group',
                className: 'first-title',
                children: [
                  {
                    key: reportPath,
                    icon: <LineChartOutlined />,
                    label: 'ตารางการเงิน',
                    onClick: onClickNavigate,
                  },
                  {
                    key: dashboardPath,
                    icon: <DashboardOutlined />,
                    label: 'แดชบอร์ด',
                    onClick: onClickNavigate,
                  },
                  {
                    key: platformPath,
                    icon: <ShopOutlined />,
                    label: 'แพลตฟอร์ม',
                    onClick: onClickNavigate,
                  },
                ],
              },
              {
                key: '2',
                label: 'ตัวช่วย',
                type: 'group',
                children: [
                  {
                    key: howToUsePath,
                    icon: <BookOutlined />,
                    label: 'แนะนำการใช้งาน',
                    onClick: onClickNavigate,
                  },
                  {
                    key: memberPath,
                    icon: <UserAddOutlined />,
                    label: 'สมาชิกในทีม',
                    onClick: onClickNavigate,
                  },
                  {
                    key: profilePath,
                    icon: <SettingOutlined />,
                    label: 'ตั้งค่า',
                    onClick: onClickNavigate,
                  },
                ],
              },
            ]}
          />

          <Flex
            className="mobile-current-info mobile-language"
            onClick={onClickSubLanguage}
          >
            <GlobalOutlined />
            <span
              className="font-size-body-2-medium font-color-text-icon-detail"
              style={{
                width: 295,
              }}
            >
              {i18n.language.toUpperCase()}
            </span>
            <RightOutlined />
          </Flex>
        </Flex>
      </Drawer>

      <Drawer
        placement="left"
        closable={false}
        open={!mobileSidebarCollapsed && !mobileSidebarProfileCollapsed}
      >
        <Flex
          gap={8}
          vertical
          style={{
            padding: 16,
          }}
        >
          <LeftOutlined
            className="mobile-current-info"
            style={{
              padding: '12px 0px',
            }}
            onClick={onClickCloseSubProfile}
          />
          <Flex
            className={`mobile-current-info hover ${
              rootPath === profilePath ? 'selected' : ''
            }`}
            style={{
              padding: 8,
            }}
            onClick={() => {
              onClickNavigate({ key: profilePath })
              setMobileSidebarProfileCollapsed(true)
              collapseMobileSidebar()
            }}
          >
            <SettingOutlined />
            <span className="font-size-body-2-regular">ตั่งค่า</span>
          </Flex>
          <Flex
            className={`mobile-current-info hover ${
              rootPath === howToUsePath ? 'selected' : ''
            }`}
            style={{
              padding: 8,
            }}
            onClick={() => {
              onClickNavigate({ key: howToUsePath })
              setMobileSidebarProfileCollapsed(true)
              collapseMobileSidebar()
            }}
          >
            <BookOutlined />
            <span className="font-size-body-2-regular">คู่มือการใช้งาน</span>
          </Flex>
          <div className="line"></div>
          <Flex
            className="mobile-current-info hover"
            style={{
              padding: 8,
            }}
            onClick={() => keycloak.logout()}
          >
            <LogoutOutlined />
            <span className="font-size-body-2-regular font-color-text-icon-detail">
              ออกจากระบบ
            </span>
          </Flex>
        </Flex>
      </Drawer>

      <Drawer
        placement="left"
        closable={false}
        open={!mobileSidebarCollapsed && !mobileSidebarCompanyCollapsed}
      >
        <Flex
          gap={8}
          vertical
          style={{
            padding: 16,
          }}
        >
          <LeftOutlined
            className="mobile-current-info"
            style={{
              padding: '12px 0px',
            }}
            onClick={onClickCloseSubCompany}
          />
          <CompanyAndPlanPopover
            list={companies.map((company) => ({
              ...company,
              id: company.companyId,
            }))}
            currentId={currentCompanyId || ''}
            btnText="ดูบริษัททั้งหมด"
            onClickOptional={() => {
              setMobileSidebarCompanyCollapsed(true)
              collapseMobileSidebar()
            }}
          />
        </Flex>
      </Drawer>

      <Drawer
        placement="left"
        closable={false}
        open={!mobileSidebarCollapsed && !mobileSidebarPlanCollapsed}
      >
        <Flex
          gap={8}
          vertical
          style={{
            padding: 16,
          }}
        >
          <LeftOutlined
            className="mobile-current-info"
            style={{
              padding: '12px 0px',
            }}
            onClick={onClickCloseSubPlan}
          />
          <CompanyAndPlanPopover
            isCompany={false}
            list={plans
              .filter((plan) => plan.companyId === currentCompanyId)
              .map((plan) => ({
                ...plan,
                id: plan.planId,
              }))}
            currentId={currentPlanId || ''}
            btnText="ดูแผนธุรกิจทั้งหมด"
            onClickOptional={() => {
              setMobileSidebarPlanCollapsed(true)
              collapseMobileSidebar()
            }}
          />
        </Flex>
      </Drawer>

      <Drawer
        placement="left"
        closable={false}
        open={!mobileSidebarCollapsed && !mobileSidebarLanguageCollapsed}
      >
        <Flex
          gap={8}
          vertical
          style={{
            padding: 16,
          }}
        >
          <LeftOutlined
            className="mobile-current-info"
            style={{
              padding: '12px 0px',
            }}
            onClick={onClickCloseSubLanguage}
          />
          <Flex
            className={`mobile-current-info hover ${
              i18n.language === 'th' ? 'selected' : ''
            }`}
            style={{
              padding: 8,
            }}
            onClick={() => {
              onClickChangeLanguage({ key: 'th' })
              setMobileSidebarLanguageCollapsed(true)
              collapseMobileSidebar()
            }}
          >
            <span className="font-size-body-2-regular">TH</span>
          </Flex>
          <Flex
            className={`mobile-current-info hover ${
              i18n.language === 'en' ? 'selected' : ''
            }`}
            style={{
              padding: 8,
            }}
            onClick={() => {
              onClickChangeLanguage({ key: 'en' })
              setMobileSidebarLanguageCollapsed(true)
              collapseMobileSidebar()
            }}
          >
            <span className="font-size-body-2-regular">EN</span>
          </Flex>
        </Flex>
      </Drawer>
    </>
  )
})

export default Sidebar
