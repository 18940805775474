import { Access, AccessType } from '../types'
import axios, { AxiosResponse } from 'axios'

export const createAccess = async (
  planId: string,
  email: string,
  type: AccessType,
): Promise<AxiosResponse<Access>> => {
  return axios.post('v1/access', {
    planId,
    email,
    type,
    subTypes: [],
    timezoneOffset: new Date().getTimezoneOffset(),
  })
}

export const updateAccess = async (
  accessId: string,
  type: AccessType,
  deactivate: boolean,
): Promise<AxiosResponse<Access>> => {
  return axios.patch('v1/access', { accessId, type, subTypes: [], deactivate })
}

export const getAllAccessesByPlan = async (
  planId: string,
): Promise<AxiosResponse<Access[]>> => {
  return axios.get('v1/access', { params: { planId } })
}

export const deleteAccess = async (
  accessId: string,
): Promise<AxiosResponse<void>> => {
  return axios.delete('v1/access', { params: { accessId } })
}

export const verifyAccess = async (
  accessId: string,
  code: string,
): Promise<AxiosResponse<Access>> => {
  return axios.patch('v1/access/verify', { accessId, code })
}

export const resendAccessCode = async (
  accessId: string,
): Promise<AxiosResponse<Access>> => {
  return axios.patch('v1/access/resend-code', {
    accessId,
    timezoneOffset: new Date().getTimezoneOffset(),
  })
}
