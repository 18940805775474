import { ThemeConfig } from 'antd'
import { config } from '.'

const { light } = config.color

const lightThemeConfig = Object.freeze({
  token: {
    fontFamily: 'DB Heavent',
    colorLink: light.textIconDetail,
    colorLinkHover: light.primaryPurple,
  },
  components: {
    Button: {
      fontWeight: 700,

      controlHeightLG: 45,
      controlHeight: 32,
      controlHeightSM: 26,

      contentFontSizeLG: 24,
      contentFontSize: 20,
      contentFontSizeSM: 18,

      contentLineHeightLG: 1,
      contentLineHeight: 1,
      contentLineHeightSM: 1,

      paddingInlineLG: 24,
      paddingInline: 16,
      paddingInlineSM: 12,

      borderRadiusLG: 100,
      borderRadius: 100,
      borderRadiusSM: 100,

      colorPrimary: light.primaryPurple,
      colorPrimaryHover: light.primaryHoverPurple,
      colorText: light.primaryPurple,
      colorBorder: light.primaryPurple,
      colorBgContainerDisabled: light.surfaceGray1,
      colorTextDisabled: light.textIconDisable,
      borderColorDisabled: light.textIconDisable,
      textHoverBg: 'transparent',
      colorBgTextActive: 'transparent',
      colorPrimaryActive: light.primaryHoverPurple,
    },
    Form: {
      labelColor: light.textIconMain,
      labelFontSize: 20,
    },
    Input: {
      colorPrimary: light.primaryPurple,
      hoverBorderColor: light.primaryPurple,
      colorTextPlaceholder: light.textIconPlaceholder,
      colorTextDisabled: light.textIconDisable,
      colorBgContainerDisabled: light.surfaceGray1,
      colorErrorBorderHover: light.statusRed,
      colorBorder: light.strokeGray,
      activeShadow: '0',
      errorActiveShadow: '0',
    },
    Select: {
      colorPrimary: light.primaryPurple,
      colorPrimaryHover: light.primaryPurple,
      colorBorder: light.strokeGray,
      optionSelectedBg: light.systemSurfaceSelected,
      optionActiveBg: light.systemSurfaceHoverPress,
      colorTextPlaceholder: light.textIconPlaceholder,
      colorIcon: light.primaryPurple,
      colorTextQuaternary: light.textIconCaption,
      controlOutline: '0',
    },
    DatePicker: {
      colorTextPlaceholder: light.textIconPlaceholder,
      colorBorder: light.strokeGray,
      colorTextDisabled: light.textIconCaption,
      controlOutline: '0',
    },
    Tooltip: {
      colorBgSpotlight: light.tooltipOverlay,
      colorTextLightSolid: light.commonWhite,
    },
    Modal: {
      colorBgMask: light.mainOverlay,
    },
    Spin: {
      colorBgMask: light.backgroundBgWhite,
      colorIcon: light.primaryPurple,
      colorPrimary: light.primaryPurple,
    },
    Menu: {
      itemSelectedBg: light.systemSurfaceSelected,
      itemSelectedColor: light.primaryPurple,
      itemColor: light.textIconDetail,
      itemHoverBg: light.systemSurfaceHoverPress,
      itemActiveBg: light.systemSurfaceHoverPress,
      itemHoverColor: light.textIconDetail,
      colorBgContainer: light.backgroundContainer,
      groupTitleColor: light.textIconCaption,
    },
    Drawer: {
      colorBgMask: light.mainOverlay,
    },
    Switch: {
      colorPrimary: light.primaryPurple,
      colorPrimaryHover: light.primaryPurple,
    },
    Tabs: {
      colorPrimary: light.primaryPurple,
      colorPrimaryActive: light.primaryPurple,
    },
    Segmented: {
      itemColor: light.textIconDisable,
      trackBg: light.surfacePurple1,
      itemHoverBg: light.surfacePurple1,
      itemActiveBg: light.surfacePurple1,
      itemSelectedBg: light.backgroundContainer,
    },
  },
} satisfies ThemeConfig)

const darkThemeConfig = Object.freeze({
  token: {},
  components: {},
} satisfies ThemeConfig)

export { lightThemeConfig, darkThemeConfig }
