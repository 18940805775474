import './App.css'
import './provider/i18n.initial'

import { ConfigProvider } from 'antd'
import MainRoute from './components/Route/Main.route'
import axios from 'axios'
import { env } from './config'
import { observer } from 'mobx-react'
import { setDefaultInterceptors } from './provider/axios.initial'
import themeStore from './stores/theme.store'
import { useKeycloak } from '@react-keycloak/web'
import { validateEnvironment } from './utils/validation-environment.util'

const App = observer(() => {
  const { keycloak } = useKeycloak()
  const { themeConfig } = themeStore

  validateEnvironment(env)
  setDefaultInterceptors()

  axios.interceptors.request.use(
    (config) => {
      config.headers.Authorization = `Bearer ${keycloak.token}`
      return config
    },
    (error) => {
      return Promise.reject(error)
    },
  )

  return (
    <ConfigProvider theme={themeConfig}>
      <MainRoute />
    </ConfigProvider>
  )
})

export default App
