import { Company, Plan } from '../../types'
import {
  Outlet,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'

import companiesStore from '../../stores/companies.store'
import { config } from '../../config'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react'
import plansStore from '../../stores/plans.store'
import redirectStore from '../../stores/redirect.store'
import { useEffect } from 'react'

const RedirectRoute = observer(() => {
  const {
    reportPath,
    dashboardPath,
    platformPath,
    howToUsePath,
    memberPath,
    profilePath,
    settingPath,
  } = config.path

  const { companies } = companiesStore
  const { plans } = plansStore
  const { stateRecheckPath } = redirectStore

  const [searchParams] = useSearchParams()
  const searchParamPlatform = searchParams.get('platform')
  const searchParamCompanyId = searchParams.get('company-id')

  const { companyId: currentCompanyId, planId: currentPlanId } = useParams<{
    companyId: string
    planId: string
  }>()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const pathnames = pathname.split('/')
  const rootPath = pathnames[1]

  useEffect(() => {
    if (
      !rootPath ||
      ![
        reportPath,
        dashboardPath,
        platformPath,
        howToUsePath,
        memberPath,
        profilePath,
        settingPath,
      ].includes(`/${rootPath}`)
    ) {
      return
    }

    const isExistingCurrentCompany = companies.find(
      (company) => company.companyId === currentCompanyId,
    )
    const isExistingCurrentPlan = getPlansByCompanyId(
      plans,
      currentCompanyId || '',
    ).find((plan) => plan.planId === currentPlanId)

    if (currentCompanyId && isEmpty(companies)) {
      navigateWithQuery()
    } else if (currentCompanyId && currentPlanId) {
      if (isExistingCurrentCompany && !isExistingCurrentPlan) {
        const plansUnderGroup = getPlansByCompanyId(plans, currentCompanyId)
        if (!isEmpty(plansUnderGroup)) {
          navigateWithQuery(`/${currentCompanyId}/${plansUnderGroup[0].planId}`)
        } else {
          navigateWithQuery(`/${currentCompanyId}`)
        }
      } else if (!isExistingCurrentCompany && !isExistingCurrentPlan) {
        redirectToDefault(companies, plans)
      }
    } else if (currentCompanyId && !currentPlanId) {
      if (isExistingCurrentCompany) {
        const plansUnderGroup = getPlansByCompanyId(plans, currentCompanyId)
        if (!isEmpty(plansUnderGroup)) {
          navigateWithQuery(`/${currentCompanyId}/${plansUnderGroup[0].planId}`)
        }
      } else if (!isExistingCurrentCompany) {
        redirectToDefault(companies, plans)
      }
    } else if (!currentCompanyId && !currentPlanId) {
      redirectToDefault(companies, plans)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, stateRecheckPath])

  const redirectToDefault = (companies: Company[], plans: Plan[]) => {
    const companiesThatHasPlan = getCompanyThatHasPlan(companies, plans)
    if (!isEmpty(companiesThatHasPlan)) {
      const plansUnderGroup = getPlansByCompanyId(
        plans,
        companiesThatHasPlan[0].companyId,
      )
      navigateWithQuery(
        `/${companiesThatHasPlan[0].companyId}/${plansUnderGroup[0].planId}`,
      )
    } else if (!isEmpty(companies)) {
      navigateWithQuery(`/${companies[0].companyId}`)
    }
  }

  const getCompanyThatHasPlan = (
    companies: Company[],
    plans: Plan[],
  ): Company[] => {
    return companies.filter((company) =>
      plans.some((plan) => plan.companyId === company.companyId),
    )
  }

  const getPlansByCompanyId = (plans: Plan[], companyId: string): Plan[] => {
    return plans.filter((plan) => plan.companyId === companyId)
  }

  const navigateWithQuery = (path = '') => {
    if (`/${rootPath}` === platformPath && searchParamPlatform) {
      navigate(rootPath + path + `?platform=${searchParamPlatform}`)
    } else if (`/${rootPath}` === settingPath && searchParamCompanyId) {
      navigate(rootPath + path + `?company-id=${searchParamCompanyId}`)
    } else {
      navigate(rootPath + path)
    }
  }

  return <Outlet />
})

export default RedirectRoute
