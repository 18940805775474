export type Access = {
  accessId: string
  recipientEmail: string
  recipientFirstName: string
  recipientLastName: string
  recipientPicture: string
  type: AccessType
  status: AccessStatus
  verificationRef?: string
  createdAt: string
  updatedAt: string
}

export enum AccessType {
  OWNER = 'owner',
  CO_WORKER = 'co-worker',
  // EDITOR = 'editor',
  VIEWER = 'viewer',
}

export enum AccessStatus {
  VERIFYING = 'verifying',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}
