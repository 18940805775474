import './LoadingBrandColor.css'

import { Spin } from 'antd'

const LoadingBrandColor = ({
  fullscreen = false,
  isOnSettingPage = false,
}: {
  fullscreen?: boolean
  isOnSettingPage?: boolean
}) => {
  return (
    <Spin
      className={`loading-center ${isOnSettingPage ? 'setting' : ''}`}
      indicator={<div className="loading-brand-color-cmpt" />}
      fullscreen={fullscreen}
    />
  )
}

export default LoadingBrandColor
