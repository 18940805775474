import { Navigate, Outlet, Route, Routes } from 'react-router-dom'

import DashboardPage from '../../pages/Dashboard/Dashboard.page'
import { Flex } from 'antd'
import MemberPage from '../../pages/Member/Member.page'
import Navbar from '../Navbar/Navbar'
import PlatformCallbackPage from '../../pages/PlatformCallback/PlatformCallback.page'
import PlatformPage from '../../pages/Platform/Platform.page'
import ProfilePage from '../../pages/Profile/Profile.page'
import RedirectRoute from './Redirect.route'
import SettingPage from '../../pages/Setting/Setting.page'
import SettingRoute from './Setting.route'
import Sidebar from '../Sidebar/Sidebar'
import { config } from '../../config'
import { observer } from 'mobx-react'
import sidebarStore from '../../stores/sidebar.store'

const Layout = ({ shrank }: { shrank: boolean }) => (
  <Flex vertical className="layout">
    <Navbar />
    <Flex gap={8}>
      <Sidebar />
      <div className={`page ${shrank ? 'shrank' : ''}`}>
        <Outlet />
      </div>
    </Flex>
  </Flex>
)

const SubRoute = observer(() => {
  const {
    reportPath,
    profilePath,
    memberPath,
    dashboardPath,
    platformPath,
    howToUsePath,
    settingPath,
  } = config.path

  const { sidebarShrank } = sidebarStore

  const subRoutes = [
    { path: reportPath, element: <DashboardPage /> },
    { path: dashboardPath, element: <DashboardPage /> },
    { path: platformPath, element: <PlatformPage /> },
    { path: howToUsePath, element: <DashboardPage /> },
  ]

  const settingRoutes = [
    { path: memberPath, element: <MemberPage /> },
    { path: profilePath, element: <ProfilePage /> },
    { path: settingPath, element: <SettingPage /> },
  ]

  return (
    <Routes>
      <Route element={<RedirectRoute />}>
        <Route element={<Layout shrank={sidebarShrank} />}>
          {subRoutes.map((route, index) => (
            <Route key={index} element={<Outlet />}>
              <Route
                path={`${route.path}/:companyId/:planId`}
                element={route.element}
              />
              <Route
                path={`${route.path}/:companyId`}
                element={route.element}
              />
              <Route path={route.path} element={route.element} />
            </Route>
          ))}

          <Route element={<SettingRoute />}>
            {settingRoutes.map((route, index) => (
              <Route key={index} element={<Outlet />}>
                <Route
                  path={`${route.path}/:companyId/:planId`}
                  element={route.element}
                />
                <Route
                  path={`${route.path}/:companyId`}
                  element={route.element}
                />
                <Route path={route.path} element={route.element} />
              </Route>
            ))}
          </Route>

          <Route path="/platform-callback" element={<PlatformCallbackPage />} />

          <Route path="/*" element={<Navigate replace to={reportPath} />} />
        </Route>
      </Route>
    </Routes>
  )
})

export default SubRoute
