import { Spin } from 'antd'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

const PlatformCallbackPage = () => {
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const status = searchParams.get('status')
    const shopId = searchParams.get('shop_id')
    const state = searchParams.get('state')
    const stateRef = localStorage.getItem('connection-state')

    if (state && state === stateRef) {
      window.opener!.postMessage({
        status,
        shopId,
        state,
      })
    }
    window.close()
  }, [searchParams])

  return <Spin indicator={<div className="loading-primary" />} fullscreen />
}

export default PlatformCallbackPage
