import { Plan } from '../types'
import { makeAutoObservable } from 'mobx'

class PlansStore {
  plans: Plan[] = []

  constructor() {
    makeAutoObservable(this)
  }

  setPlans = (plans: Plan[]) => {
    this.plans = plans
  }

  unshiftPlan = (newPlan: Plan) => {
    this.plans.unshift(newPlan)
  }

  removePlan = (planId: string) => {
    const deletedIndex = this.plans.findIndex((plan) => plan.planId === planId)
    if (deletedIndex !== -1) {
      this.plans.splice(deletedIndex, 1)
    }
  }

  removePlansByCompany = (companyId: string) => {
    this.plans
      .map((plan, index) => ({ ...plan, index }))
      .reverse()
      .forEach((plan) => {
        if (plan.companyId === companyId) {
          this.plans.splice(plan.index, 1)
        }
      })
  }

  updatePlan = (updatedPlan: Plan) => {
    const index = this.plans.findIndex(
      (plan) => plan.planId === updatedPlan.planId,
    )
    if (index !== -1) {
      this.plans[index] = updatedPlan
    }
  }
}
const plansStore = new PlansStore()
export default plansStore
