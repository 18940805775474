import './Setting.css'

import {
  Alert,
  Button,
  DatePicker,
  Drawer,
  Flex,
  Form,
  FormProps,
  Input,
  Modal,
  Popover,
  Select,
  Table,
  message,
} from 'antd'
import {
  BusinessType,
  BusinessTypes,
  Company,
  Country,
  Currency,
  Plan,
  PlanTemplate,
  SearchSegmentedTab,
} from '../../types'
import {
  DeleteOutlined,
  EditOutlined,
  FileTextOutlined,
  InfoCircleOutlined,
  MoreOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import {
  createCompany,
  deleteCompany,
  updateCompany,
} from '../../services/company.service'
import { createPlan, deletePlan, updatePlan } from '../../services/plan.service'
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { useEffect, useState } from 'react'

import { AxiosError } from 'axios'
import PlanTemplateRadio from '../../components/PlanTemplateRadio/PlanTemplateRadio'
import SearchBusinessTypeAdvice from '../../components/SearchBusinessTypeAdvice/SearchBusinessTypeAdvice'
import SearchBusinessTypeSegmented from '../../components/SearchBusinessTypeSegmented/SearchBusinessTypeSegmented'
import SearchBusinessTypeSelect from '../../components/SearchBusinessTypeSelect/SearchBusinessTypeSelect'
import companiesStore from '../../stores/companies.store'
import dayjs from 'dayjs'
import { getTemplateByBusinessType } from '../../utils/planTemplate.util'
import { isEmpty } from 'lodash'
import plansStore from '../../stores/plans.store'
import redirectStore from '../../stores/redirect.store'
import { useTranslation } from 'react-i18next'

const { Option } = Select
type CompanyForm = Pick<
  Company,
  'companyId' | 'name' | 'businessType' | 'country' | 'currency'
>
type PlanForm = Pick<
  Plan,
  'planId' | 'companyId' | 'name' | 'template' | 'startDate'
>

const SettingPage = () => {
  const { companies, unshiftCompany, removeCompany } = companiesStore
  const { plans, unshiftPlan, removePlan, removePlansByCompany } = plansStore
  const { recheckPath } = redirectStore

  const [searchParams] = useSearchParams()
  const searchParamCompanyId = searchParams.get('company-id')
  const searchParamCompany = companies.find(
    (company) => company.companyId === searchParamCompanyId,
  )

  const { t } = useTranslation()

  const navigate = useNavigate()

  const [messageApi, contextHolder] = message.useMessage()

  const [isCompanyDrawerOpen, setIsCompanyDrawerOpen] = useState(false)
  const [isBusinessTypeDrawerOpen, setIsBusinessTypeDrawerOpen] =
    useState(false)
  const [isLoadingCreateUpdateCompany, setIsLoadingCreateUpdateCompany] =
    useState(false)
  const [isEditCompany, setIsEditCompany] = useState(false)
  const [editCompanyName, setEditCompanyName] = useState('')
  const [companyForm] = Form.useForm()

  const [isPlanDrawerOpen, setIsPlanDrawerOpen] = useState(false)
  const [isPlanTemplateDrawerOpen, setIsPlanTemplateDrawerOpen] =
    useState(false)
  const [isLoadingCreateUpdatePlan, setIsLoadingCreateUpdatePlan] =
    useState(false)
  const [isEditPlan, setIsEditPlan] = useState(false)
  const [editPlanName, setEditPlanName] = useState('')
  const [planForm] = Form.useForm()

  const [valueAi, setValueAi] = useState<BusinessType>()
  const [optionsAi, setOptionsAi] = useState<BusinessType[]>([])
  const [valueNormal, setValueNormal] = useState<BusinessType>()
  const [currentTab, setCurrentTab] = useState<SearchSegmentedTab>(
    SearchSegmentedTab.LEFT,
  )

  const [currentPlanTemplate, setCurrentPlanTemplate] = useState(
    PlanTemplate.GENERAL_BUSINESS,
  )
  const [planTemplateAi, setPlanTemplateAi] = useState(
    PlanTemplate.GENERAL_BUSINESS,
  )
  const [planTemplateNormal, setPlanTemplateNormal] = useState(
    PlanTemplate.GENERAL_BUSINESS,
  )

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [deleteId, setDeleteId] = useState('')
  const [deleteName, setDeleteName] = useState('')
  const [isDeleteCompany, setIsDeleteCompany] = useState(false)
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)

  useEffect(() => {
    if (
      !companies.some((company) => company.companyId === searchParamCompanyId)
    ) {
      navigate({
        search: createSearchParams({}).toString(),
      })
    }
  }, [companies, navigate, searchParamCompanyId])

  const onCreateCompanyDrawerOpen = () => {
    companyForm.resetFields()
    companyForm.setFieldsValue({ country: Country.TH, currency: Currency.THB })
    setValueAi(undefined)
    setValueNormal(undefined)
    setOptionsAi([])
    setCurrentTab(SearchSegmentedTab.LEFT)
    setIsEditCompany(false)
    setIsCompanyDrawerOpen(true)
  }

  const onEditCompanyDrawerOpen = (company: Company) => {
    const { companyId, name, businessType, country, currency } = company
    companyForm.setFieldsValue({
      companyId,
      name,
      businessType,
      country,
      currency,
    })
    setOptionsAi(BusinessTypes)
    setValueAi(businessType)
    setValueNormal(businessType)
    setEditCompanyName(name)
    setIsEditCompany(true)
    setCurrentTab(SearchSegmentedTab.LEFT)
    setIsCompanyDrawerOpen(true)
  }

  const onCreatePlanDrawerOpen = (
    companyId: string,
    businessType: BusinessType,
  ) => {
    const currentPlanTemplate = getTemplateByBusinessType(businessType)
    planForm.resetFields()
    planForm.setFieldsValue({ template: currentPlanTemplate, companyId })
    setCurrentPlanTemplate(currentPlanTemplate)
    setPlanTemplateNormal(currentPlanTemplate)
    setValueAi(undefined)
    setOptionsAi([])
    setIsEditPlan(false)
    setCurrentTab(SearchSegmentedTab.LEFT)
    setIsPlanDrawerOpen(true)
  }

  const onEditPlanDrawerOpen = (plan: Plan) => {
    const { template, companyId, planId, name, startDate } = plan
    planForm.resetFields()
    planForm.setFieldsValue({
      planId,
      companyId,
      name,
      template,
      startDate: dayjs(startDate),
    })
    setCurrentPlanTemplate(template)
    setEditPlanName(name)
    setIsEditPlan(true)
    setCurrentTab(SearchSegmentedTab.LEFT)
    setIsPlanDrawerOpen(true)
  }

  const onFinishCompany: FormProps<CompanyForm>['onFinish'] = (
    _companyForm: CompanyForm,
  ) => {
    setIsLoadingCreateUpdateCompany(true)

    if (_companyForm.companyId) {
      updateCompany(_companyForm)
        .then((responses) => {
          companiesStore.updateCompany(responses.data)
          setIsCompanyDrawerOpen(false)
          messageApi.open({
            type: 'success',
            content: 'แก้ไขเรียบร้อยแล้ว',
          })
        })
        .catch((error: AxiosError) => {
          if (error.response?.status === 409) {
            companyForm.setFields([
              {
                name: 'name',
                errors: ['ชื่อบริษัทนี้มีการใช้งานแล้ว โปรดกรอกชื่อใหม่'],
              },
            ])
          }
        })
        .finally(() => setIsLoadingCreateUpdateCompany(false))
    } else {
      createCompany(_companyForm)
        .then((response) => {
          unshiftCompany(response.data)
          recheckPath()
          setIsCompanyDrawerOpen(false)
          messageApi.open({
            type: 'success',
            content: 'เพิ่มบริษัทเรียบร้อยแล้ว',
          })
        })
        .catch((error: AxiosError) => {
          if (error.response?.status === 409) {
            companyForm.setFields([
              {
                name: 'name',
                errors: ['ชื่อบริษัทนี้มีการใช้งานแล้ว โปรดกรอกชื่อใหม่'],
              },
            ])
          }
        })
        .finally(() => setIsLoadingCreateUpdateCompany(false))
    }
  }

  const onFinishPlan: FormProps<PlanForm>['onFinish'] = (
    _planForm: PlanForm,
  ) => {
    setIsLoadingCreateUpdatePlan(true)

    if (_planForm.planId) {
      updatePlan(_planForm)
        .then((responses) => {
          plansStore.updatePlan(responses.data)
          setIsPlanDrawerOpen(false)
          messageApi.open({
            type: 'success',
            content: 'แก้ไขเรียบร้อยแล้ว',
          })
        })
        .catch((error: AxiosError) => {
          if (error.response?.status === 409) {
            planForm.setFields([
              {
                name: 'name',
                errors: ['ชื่อแผนธุรกิจนี้มีการใช้งานแล้ว โปรดกรอกชื่อใหม่'],
              },
            ])
          }
        })
        .finally(() => setIsLoadingCreateUpdatePlan(false))
    } else {
      createPlan(_planForm)
        .then((response) => {
          unshiftPlan(response.data)
          recheckPath()
          setIsPlanDrawerOpen(false)
          messageApi.open({
            type: 'success',
            content: 'เพิ่มแผนธุรกิจเรียบร้อยแล้ว',
          })
        })
        .catch((error: AxiosError) => {
          if (error.response?.status === 409) {
            planForm.setFields([
              {
                name: 'name',
                errors: ['ชื่อแผนธุรกิจนี้มีการใช้งานแล้ว โปรดกรอกชื่อใหม่'],
              },
            ])
          }
        })
        .finally(() => setIsLoadingCreateUpdatePlan(false))
    }
  }

  const onBusinessTypeDrawerConfirm = () => {
    if (currentTab === SearchSegmentedTab.LEFT) {
      valueAi && companyForm.setFieldValue('businessType', valueAi)
    } else {
      valueNormal && companyForm.setFieldValue('businessType', valueNormal)
    }
    companyForm.validateFields(['businessType'])
    setIsBusinessTypeDrawerOpen(false)
  }

  const onPlanTemplateDrawerConfirm = () => {
    if (currentTab === SearchSegmentedTab.LEFT && valueAi) {
      setCurrentPlanTemplate(planTemplateAi)
      planForm.setFieldValue('template', planTemplateAi)
      if (currentPlanTemplate !== planTemplateAi) {
        messageApi.open({
          type: 'success',
          content: 'เปลี่ยนแผนธุรกิจเรียบร้อยแล้ว',
        })
      }
    } else if (currentTab === SearchSegmentedTab.RIGHT) {
      setCurrentPlanTemplate(planTemplateNormal)
      planForm.setFieldValue('template', planTemplateNormal)
      if (currentPlanTemplate !== planTemplateNormal) {
        messageApi.open({
          type: 'success',
          content: 'เปลี่ยนแผนธุรกิจเรียบร้อยแล้ว',
        })
      }
    }
    setIsPlanTemplateDrawerOpen(false)
  }

  const onClickOpenDeleteModal = (
    deleteId: string,
    deleteName: string,
    isDeleteCompany: boolean,
  ) => {
    setIsDeleteCompany(isDeleteCompany)
    setIsDeleteModalOpen(true)
    setDeleteId(deleteId)
    setDeleteName(deleteName)
  }

  const onClickDelete = () => {
    setIsLoadingDelete(true)

    if (isDeleteCompany) {
      deleteCompany(deleteId)
        .then(() => {
          removeCompany(deleteId)
          removePlansByCompany(deleteId)
          recheckPath()
          messageApi.open({
            type: 'success',
            content: 'ลบเรียบร้อยแล้ว',
          })
        })
        .finally(() => {
          setIsLoadingDelete(false)
          setIsDeleteModalOpen(false)
        })
    } else {
      deletePlan(deleteId)
        .then(() => {
          removePlan(deleteId)
          recheckPath()
          messageApi.open({
            type: 'success',
            content: 'ลบเรียบร้อยแล้ว',
          })
        })
        .finally(() => {
          setIsLoadingDelete(false)
          setIsDeleteModalOpen(false)
        })
    }
  }

  return (
    <Flex className="setting-page" gap={16} vertical>
      {!searchParamCompanyId ? (
        <>
          <Flex align="center" justify="space-between">
            <span className="font-size-h2-bold font-color-text-icon-main">
              บริษัท และแผนธุรกิจ
            </span>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={onCreateCompanyDrawerOpen}
            >
              เพิ่มบริษัท
            </Button>
          </Flex>

          {isEmpty(companies) && (
            <Flex
              align="center"
              vertical
              style={{ margin: '102px 0px 40px 0px' }}
            >
              <div className="no-data-icon" />
              <span
                className="font-size-body-2-bold font-color-text-icon-main"
                style={{ lineHeight: '24px' }}
              >
                ยังไม่มีบริษัท
              </span>
              <span
                className="font-size-body-2-regular font-color-text-icon-detail"
                style={{ lineHeight: '24px' }}
              >
                เพิ่มบริษัท เพื่อเริ่มต้นใช้งาน FP&A
              </span>
            </Flex>
          )}

          <div className="group-card">
            {Object.values(companies).map((company, key) => (
              <Flex className="company-card" key={key}>
                <Flex gap={8} align="center">
                  <div className="business-type-icon" />
                  <Flex
                    vertical
                    flex={1}
                    style={{
                      width: 'calc(100% - 148px)',
                    }}
                  >
                    <span className="font-size-body-2-bold font-color-text-icon-main text-ellipsis">
                      {company.name}
                    </span>
                    <span className="font-size-tooltip-regular font-color-text-icon-detail text-ellipsis">
                      {t(company.businessType)}
                    </span>
                  </Flex>
                  <Popover
                    placement="bottomRight"
                    arrow={false}
                    content={
                      <div className="company-popover-cmpt">
                        <Flex
                          gap={8}
                          className="item font-size-body-2-regular font-color-text-icon-detail"
                          onClick={() => {
                            onEditCompanyDrawerOpen(company)
                          }}
                        >
                          <EditOutlined />
                          <span>แก้ไข</span>
                        </Flex>
                        <Flex
                          gap={8}
                          className="item font-size-body-2-regular font-color-text-icon-detail"
                          onClick={() =>
                            onClickOpenDeleteModal(
                              company.companyId,
                              company.name,
                              true,
                            )
                          }
                        >
                          <DeleteOutlined />
                          <span>ลบ</span>
                        </Flex>
                      </div>
                    }
                  >
                    <MoreOutlined className="font-color-text-icon-caption" />
                  </Popover>
                </Flex>

                {plans.some((plan) => plan.companyId === company.companyId) ? (
                  <>
                    <Flex gap={8} vertical>
                      {plans
                        .filter((plan) => plan.companyId === company.companyId)
                        .slice(0, 2)
                        .map((plan, key) => (
                          <Flex
                            key={key}
                            gap={8}
                            align="center"
                            style={{
                              padding: '15px 16px',
                              borderRadius: 16,
                              border: '1px solid var(--stroke-gray)',
                            }}
                          >
                            <FileTextOutlined className="font-color-text-icon-caption" />
                            <span className="font-size-body-2-medium font-color-text-icon-main text-ellipsis">
                              {plan.name}
                            </span>
                          </Flex>
                        ))}
                    </Flex>

                    <Flex style={{ marginTop: 'auto' }}>
                      <Button
                        type="primary"
                        size="small"
                        style={{ width: 'fit-content' }}
                        icon={<PlusOutlined />}
                        onClick={() =>
                          onCreatePlanDrawerOpen(
                            company.companyId,
                            company.businessType,
                          )
                        }
                      >
                        เพิ่มแผนธุรกิจ
                      </Button>
                      <Button
                        type="text"
                        size="small"
                        onClick={() => {
                          navigate({
                            search: createSearchParams({
                              'company-id': company.companyId,
                            }).toString(),
                          })
                        }}
                        style={{ width: 'fit-content' }}
                      >
                        ดูทั้งหมด
                      </Button>
                    </Flex>
                  </>
                ) : (
                  <Flex align="center" vertical>
                    <div className="no-data-icon" />
                    <span className="font-size-body-2-bold font-color-text-icon-main">
                      ยังไม่มีแผนธุรกิจ
                    </span>
                    <span className="font-size-body-2-regular font-color-text-icon-detail">
                      เพิ่มแผนธุรกิจ เพื่อเริ่มต้นใช้งาน FP&A
                    </span>
                    <Button
                      type="primary"
                      size="small"
                      icon={<PlusOutlined />}
                      style={{ marginTop: 16 }}
                      onClick={() =>
                        onCreatePlanDrawerOpen(
                          company.companyId,
                          company.businessType,
                        )
                      }
                    >
                      เพิ่มแผนธุรกิจ
                    </Button>
                  </Flex>
                )}
              </Flex>
            ))}
          </div>
        </>
      ) : (
        <Flex
          gap={16}
          vertical
          style={{
            height: '-webkit-fill-available',
          }}
        >
          <Flex gap={8}>
            <span
              className="company-and-plan-btn"
              onClick={() =>
                navigate({
                  search: createSearchParams({}).toString(),
                })
              }
            >
              บริษัท และแผนธุรกิจ
            </span>
            <span className="font-size-caption-regular font-color-text-icon-detail">
              /
            </span>
            <span className="font-size-caption-regular font-color-primary-purple">
              {searchParamCompany?.name}
            </span>
          </Flex>
          <Flex className="all-plans-in-company-header">
            <span className="font-size-h3-bold font-color-text-icon-main">
              แผนธุรกิจทั้งหมดของบริษัท: {searchParamCompany?.name}
            </span>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() =>
                onCreatePlanDrawerOpen(
                  searchParamCompanyId,
                  searchParamCompany!.businessType,
                )
              }
            >
              เพิ่มแผนธุรกิจ
            </Button>
          </Flex>
          <Flex vertical className="all-plan">
            <Table
              scroll={{ x: 700 }}
              className="plan-table"
              pagination={false}
              locale={{
                emptyText: (
                  <Flex
                    align="center"
                    vertical
                    style={{ margin: '16px 0px 40px 0px' }}
                  >
                    <div className="no-data-icon" />
                    <span
                      className="font-size-body-2-bold font-color-text-icon-main"
                      style={{ lineHeight: '24px' }}
                    >
                      ยังไม่มีแผนธุรกิจ
                    </span>
                    <span
                      className="font-size-body-2-regular font-color-text-icon-detail"
                      style={{ lineHeight: '24px' }}
                    >
                      เพิ่มแผนธุรกิจ เพื่อเริ่มต้นใช้งาน FP&A
                    </span>
                  </Flex>
                ),
              }}
              columns={[
                {
                  title: 'ชื่อแผนธุรกิจ',
                  width: '40%',
                  ellipsis: true,
                  className: 'first-column',
                  dataIndex: 'name',
                  render: (name: string) => (
                    <Flex gap={8}>
                      <FileTextOutlined
                        className="font-color-text-icon-caption"
                        style={{ fontSize: 16 }}
                      />
                      <span
                        className="font-size-body-2-medium font-color-text-icon-main"
                        style={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          lineHeight: '24px',
                        }}
                      >
                        {name}
                      </span>
                    </Flex>
                  ),
                },
                {
                  title: 'ประเภทแผนธุรกิจ',
                  width: '30%',
                  ellipsis: true,
                  dataIndex: 'template',
                  render: (template: PlanTemplate) => (
                    <span className="font-size-body-2-regular font-color-text-icon-main">
                      {t(template)}
                    </span>
                  ),
                },
                {
                  title: 'เดือนเริ่มต้นรอบบัญชี',
                  width: '30%',
                  ellipsis: true,
                  dataIndex: 'startDate',
                  render: (startDate: string) => (
                    <span className="font-size-body-2-regular font-color-text-icon-main">
                      {new Date(startDate).toLocaleDateString('th-TH', {
                        year: 'numeric',
                        month: 'narrow',
                      })}
                    </span>
                  ),
                },
                {
                  title: 'การจัดการ',
                  width: 100,
                  align: 'end',
                  className: 'last-column',
                  render: (plan: Plan) => (
                    <Flex gap={24} className="icon-management">
                      <EditOutlined
                        onClick={() => {
                          onEditPlanDrawerOpen(plan)
                        }}
                      />
                      <DeleteOutlined
                        onClick={() =>
                          onClickOpenDeleteModal(plan.planId, plan.name, false)
                        }
                      />
                    </Flex>
                  ),
                },
              ]}
              dataSource={plans
                .filter((plan) => plan.companyId === searchParamCompanyId)
                .map((plan, key) => ({
                  ...plan,
                  key,
                }))}
            />
          </Flex>
        </Flex>
      )}

      <Drawer
        title={isEditCompany ? `แก้ไข: ${editCompanyName}` : 'เพิ่มบริษัท'}
        placement="right"
        maskClosable={false}
        width={472}
        onClose={() => setIsCompanyDrawerOpen(false)}
        open={isCompanyDrawerOpen}
      >
        <Form form={companyForm} layout="vertical" onFinish={onFinishCompany}>
          <Form.Item hidden name="companyId">
            <Input />
          </Form.Item>
          <Form.Item
            label="ชื่อบริษัท"
            name="name"
            rules={[{ required: true, message: 'โปรดกรอกชื่อบริษัท' }]}
          >
            <Input placeholder="กรอกชื่อบริษัท" allowClear />
          </Form.Item>
          <Form.Item
            label="ค้นหาประเภทธุรกิจหลัก"
            name="businessType"
            rules={[{ required: true, message: 'โปรดเลือกประเภทธุรกิจหลัก' }]}
            tooltip={{
              title:
                'ประเภทธุรกิจ อ้างอิงตามการจัดประเภทมาตรฐาน\nอุตสาหกรรม (ประเทศไทย) ปี 2552\nของกรมการจัดหางาน กระทรวงแรงงาน',
              icon: <InfoCircleOutlined />,
            }}
          >
            <Select
              disabled={isEditCompany}
              open={false}
              placeholder="พิมพ์คำค้นหา เช่น ขายข้าวมันไก่ แบบมีหน้าร้านและขายออนไลน์"
              filterOption={false}
              onClick={() => {
                if (!isEditCompany) {
                  setIsBusinessTypeDrawerOpen(true)
                }
              }}
              suffixIcon={<SearchOutlined />}
              notFoundContent={null}
              options={BusinessTypes.map((businessType: string) => ({
                label: t(businessType),
                value: businessType,
              }))}
              allowClear
            />
          </Form.Item>
          <Form.Item
            label="ประเทศ"
            name="country"
            rules={[{ required: true, message: 'โปรดเลือกประเทศ' }]}
          >
            <Select
              disabled={isEditCompany}
              placeholder="เลือกประเทศ"
              allowClear
            >
              <Option value={Country.TH}>🇹🇭 ไทย</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="สกุลเงิน"
            name="currency"
            rules={[{ required: true, message: 'โปรดเลือกสกุลเงิน' }]}
          >
            <Select
              disabled={isEditCompany}
              placeholder="เลือกสกุลเงิน"
              allowClear
            >
              <Option value={Currency.THB}>ไทยบาท (฿)</Option>
            </Select>
          </Form.Item>
          <Flex justify="end" style={{ marginTop: 40 }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoadingCreateUpdateCompany}
            >
              {isEditCompany ? 'ยืนยันการแก้ไข' : 'เสร็จสิ้น'}
            </Button>
          </Flex>
        </Form>
      </Drawer>

      <Drawer
        title="ค้นหาประเภทธุรกิจหลัก"
        placement="right"
        maskClosable={false}
        width={472}
        onClose={() => setIsBusinessTypeDrawerOpen(false)}
        open={isBusinessTypeDrawerOpen}
      >
        <Flex vertical gap={24}>
          <SearchBusinessTypeSegmented
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            tabTitleLeft="ค้นหาด้วย AI"
            tabTitleRight="ค้นหาจากรายการ"
            tabLeftElement={
              <>
                <SearchBusinessTypeSelect
                  value={valueAi}
                  setValue={setValueAi}
                  options={optionsAi}
                  setOptions={setOptionsAi}
                  placeholder="พิมพ์คำค้นหา เช่น ขายข้าวมันไก่ แบบมีหน้าร้านและขายออนไลน์"
                  notFoundTitle1="พิมพ์คำค้นหาใหม่"
                  notFoundTitle2="โปรดพิมพ์อธิบายเกี่ยวกับธุรกิจของคุณให้ชัดเจนขึ้น"
                />
                <SearchBusinessTypeAdvice
                  isHide={!!valueAi}
                  description1="พิมพ์อธิบายเกี่ยวกับธุรกิจของคุณ"
                  description2="เพื่อให้ AI เราช่วยแนะนำ"
                />
              </>
            }
            tabRightElement={
              <>
                <SearchBusinessTypeSelect
                  value={valueNormal}
                  setValue={setValueNormal}
                  options={BusinessTypes}
                  setOptions={() => {}}
                  filterOption={true}
                  optionFilterProp="label"
                  placeholder="พิมพ์คำค้นหา หรือเลือกจากรายการ"
                  notFoundTitle1="พิมพ์คำค้นหาใหม่"
                  notFoundTitle2="โปรดพิมพ์คำค้นหาใหม่อีกครั้ง"
                />
                <SearchBusinessTypeAdvice
                  isHide={!!valueNormal}
                  description1="พิมพ์คำค้น หรือเลือกประเภทธุรกิจ"
                  description2="จากรายการที่เราเตรียมไว้ให้"
                />
              </>
            }
          />
          <Flex justify="end">
            <Button type="primary" onClick={onBusinessTypeDrawerConfirm}>
              ยืนยัน
            </Button>
          </Flex>
        </Flex>
      </Drawer>

      <Drawer
        title={isEditPlan ? `แก้ไข: ${editPlanName}` : 'เพิ่มแผนธุรกิจ'}
        placement="right"
        maskClosable={false}
        width={472}
        onClose={() => setIsPlanDrawerOpen(false)}
        open={isPlanDrawerOpen}
      >
        {isEditPlan ? (
          <></>
        ) : (
          <Alert
            description="ประเภทแผนธุรกิจที่เราแนะนำ ยึดจากหมวดของบริษัท สามารถเปลี่ยน โดยการให้ AI ช่วยแนะนำ หรือเลือกจากรายการที่เราเตรียมไว้ให้"
            type="info"
            showIcon
            style={{ marginBottom: 24 }}
          />
        )}

        <Form form={planForm} layout="vertical" onFinish={onFinishPlan}>
          <Form.Item hidden name="companyId">
            <Input />
          </Form.Item>
          <Form.Item hidden name="planId">
            <Input />
          </Form.Item>
          <Form.Item
            label="ชื่อแผนธุรกิจ"
            name="name"
            rules={[{ required: true, message: 'โปรดกรอกชื่อแผนธุรกิจ' }]}
          >
            <Input placeholder="กรอกชื่อแผนธุรกิจ" allowClear />
          </Form.Item>
          <Flex
            justify="space-between"
            style={{ lineHeight: '24px', marginBottom: 4 }}
          >
            <span className="font-size-body-2-bold">
              ประเภทแผนธุรกิจที่เราแนะนำ
            </span>
            {isEditPlan ? (
              <></>
            ) : (
              <span
                onClick={() => setIsPlanTemplateDrawerOpen(true)}
                className="font-size-text-link-2-regular font-color-text-icon-detail change-btn"
              >
                เปลี่ยน
              </span>
            )}
          </Flex>
          <Form.Item
            name="template"
            rules={[{ required: true, message: 'โปรดเลือกประเภทของแผนธุรกิจ' }]}
          >
            <PlanTemplateRadio
              isOnModal={true}
              value={currentPlanTemplate}
              setValue={setCurrentPlanTemplate}
              options={[currentPlanTemplate]}
            />
          </Form.Item>
          <Form.Item
            label="เลือกเดือนเริ่มต้นรอบบัญชี"
            name="startDate"
            rules={[
              { required: true, message: 'โปรดเลือกเดือนเริ่มต้นรอบบัญชี' },
            ]}
            tooltip={{
              title:
                'เดือนเริ่มต้นรอบบัญชีจะเป็นตัวกำหนด\nระยะเวลาในรายงานของคุณ',
              icon: <InfoCircleOutlined />,
            }}
          >
            <DatePicker
              // disabled={isEditPlan}
              picker="month"
              placeholder="เลือกเดือนเริ่มต้นรอบบัญชี"
              format={'MMMM YYYY'}
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Flex justify="end" style={{ marginTop: 40 }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoadingCreateUpdatePlan}
            >
              {isEditPlan ? 'ยืนยันการแก้ไข' : 'เสร็จสิ้น'}
            </Button>
          </Flex>
        </Form>
      </Drawer>

      <Drawer
        title="เปลี่ยนประเภทแผนธุรกิจ"
        placement="right"
        maskClosable={false}
        width={472}
        onClose={() => setIsPlanTemplateDrawerOpen(false)}
        open={isPlanTemplateDrawerOpen}
      >
        <Flex vertical gap={24}>
          <SearchBusinessTypeSegmented
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            tabTitleLeft="ค้นหาด้วย AI"
            tabTitleRight="เลือกจากรายการ"
            tabLeftElement={
              <>
                <SearchBusinessTypeSelect
                  value={valueAi}
                  setValue={setValueAi}
                  options={optionsAi}
                  setOptions={setOptionsAi}
                  placeholder="พิมพ์คำค้นหา เช่น ขายข้าวมันไก่ แบบมีหน้าร้านและขายออนไลน์"
                  notFoundTitle1="พิมพ์คำค้นหาใหม่"
                  notFoundTitle2="โปรดพิมพ์อธิบายเกี่ยวกับธุรกิจของคุณให้ชัดเจนขึ้น"
                  onChangeCallBack={(optionSelected: BusinessType) => {
                    if (optionSelected) {
                      setPlanTemplateAi(
                        getTemplateByBusinessType(optionSelected),
                      )
                    }
                  }}
                />
                {valueAi ? (
                  <Flex style={{ height: 234, marginTop: 16 }} vertical>
                    <PlanTemplateRadio
                      isOnModal={true}
                      value={planTemplateAi}
                      setValue={setPlanTemplateAi}
                      options={['ประเภทแผนธุรกิจที่เราแนะนำ', planTemplateAi]}
                    />
                  </Flex>
                ) : (
                  <SearchBusinessTypeAdvice
                    description1="พิมพ์อธิบายเกี่ยวกับธุรกิจของคุณ"
                    description2="เพื่อให้ AI เราช่วยแนะนำ"
                  />
                )}
              </>
            }
            tabRightElement={
              <Flex
                style={{
                  height: 'calc(100vh - 249px)',
                  overflowY: 'scroll',
                  overflowX: 'hidden',
                  scrollbarWidth: 'none',
                }}
                vertical
              >
                <PlanTemplateRadio
                  isOnModal={true}
                  value={planTemplateNormal}
                  setValue={setPlanTemplateNormal}
                  options={[
                    'ธุรกิจขายส่ง และการขายปลีก การซ่อมยานยนต์ และจักรยานยนต์ (หมวด G)',
                    PlanTemplate.ONLINE_STORE,
                    PlanTemplate.CLOTHING_STORE,
                    PlanTemplate.COSMETIC_STORE,
                    PlanTemplate.ELECTRICAL_STORE,
                    PlanTemplate.PET_STORE,
                    PlanTemplate.PHARMACY,
                    'ธุรกิจที่พัก และบริการด้านอาหาร (หมวด I)',
                    PlanTemplate.GENERAL_RESTAURANT,
                    PlanTemplate.CAFE,
                    PlanTemplate.LIQUOR_STORE_OR_PUB,
                    PlanTemplate.CATERING,
                    'ธุรกิจการก่อสร้าง (หมวด F)',
                    PlanTemplate.CONSTRUCTION_CONTRACTOR,
                    'ธุรกิจอสังหาริมทรัพย์ (หมวด L)',
                    PlanTemplate.HOUSE_CONDO_APARTMENT_OFFICE_FOR_RENT,
                    PlanTemplate.REAL_ESTATE_AGENT,
                    'ธุรกิจอื่นๆ (ที่ไม่ได้อยู่ในรายการ)',
                    PlanTemplate.GENERAL_BUSINESS,
                  ]}
                />
              </Flex>
            }
          />
          <Flex justify="end">
            <Button type="primary" onClick={onPlanTemplateDrawerConfirm}>
              ยืนยันการเปลี่ยน
            </Button>
          </Flex>
        </Flex>
      </Drawer>

      <Modal
        open={isDeleteModalOpen}
        maskClosable={false}
        closable={false}
        width={416}
        title={`คุณต้องการลบ${
          isDeleteCompany ? 'บริษัท' : 'แผนธุรกิจ'
        } ${deleteName} ใช่ไหม?`}
        className="modal-platform"
        footer={
          <Flex gap={8} justify="end" style={{ marginTop: 24 }}>
            <Button type="text" onClick={() => setIsDeleteModalOpen(false)}>
              ไว้ทีหลัง
            </Button>
            <Button
              type="primary"
              loading={isLoadingDelete}
              onClick={onClickDelete}
            >
              ลบ
            </Button>
          </Flex>
        }
      >
        {isDeleteCompany
          ? 'หากคุณลบบริษัท แผนธุรกิจที่คุณสร้างไว้ภายใต้บริษัทนี้ จะถูกลบออกทั้งหมดเช่นกัน'
          : 'หากคุณลบแผนธุรกิจ ข้อมูลในตารางการเงินที่ถูกสร้างไว้ภายใต้้แผนธุรกิจนี้ จะถูกลบออกทั้งหมดเช่นกัน'}
      </Modal>

      {contextHolder}
    </Flex>
  )
}

export default SettingPage
