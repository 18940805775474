import './PlanTemplateRadio.css'

import { Dispatch, SetStateAction } from 'react'
import { Radio, RadioChangeEvent } from 'antd'

import { PlanTemplate } from '../../types'
import { useTranslation } from 'react-i18next'

const PlanTemplateRadio = ({
  value,
  setValue,
  options,
  isOnModal = false,
}: {
  value: PlanTemplate
  setValue: Dispatch<SetStateAction<PlanTemplate>>
  options: (PlanTemplate | string)[]
  isOnModal?: boolean
}) => {
  const { t } = useTranslation()

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value)
  }

  return (
    <Radio.Group
      className="plan-template-radio-cmpt"
      value={value}
      onChange={onChange}
    >
      {options.map((option, index) =>
        !Object.values(PlanTemplate).includes(option as any) ? (
          <span
            key={index}
            className="font-size-body-2-bold title"
            style={{
              marginTop: index === 0 ? 0 : 8,
            }}
          >
            {option}
          </span>
        ) : (
          <Radio.Button
            key={index}
            className={`${option} ${isOnModal ? 'on-modal' : 'on-page'}`}
            value={option}
          >
            {t(option)}
          </Radio.Button>
        ),
      )}
    </Radio.Group>
  )
}

export default PlanTemplateRadio
