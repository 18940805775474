import './SearchBusinessTypeAdvice.css'

import { Flex } from 'antd'

const SearchBusinessTypeAdvice = ({
  isHide = false,
  description1,
  description2,
}: {
  isHide?: boolean
  description1: string
  description2: string
}) => {
  return (
    <Flex
      className={`search-business-type-advice-cmpt font-size-body-2-regular font-color-text-icon-detail ${
        isHide ? 'hidden' : ''
      }`}
    >
      <div className="advice-icon"></div>
      <span>{description1}</span>
      <span>{description2}</span>
    </Flex>
  )
}

export default SearchBusinessTypeAdvice
