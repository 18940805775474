import { makeAutoObservable } from 'mobx'

class SidebarStore {
  sidebarShrank: boolean = false
  mobileSidebarCollapsed: boolean = true

  constructor() {
    makeAutoObservable(this)
  }

  shrinkSidebar = () => {
    this.sidebarShrank = true
  }

  unShrinkSidebar = () => {
    this.sidebarShrank = false
  }

  collapseMobileSidebar = () => {
    this.mobileSidebarCollapsed = true
  }

  unCollapseMobileSidebar = () => {
    this.mobileSidebarCollapsed = false
  }
}
const sidebarStore = new SidebarStore()
export default sidebarStore
