export enum SearchSelectState {
  EMPTY = 'empty',
  LOADING = 'loading',
  NOTFOUND = 'notfound',
  ERROR = 'error',
}

export enum SearchSegmentedTab {
  LEFT = 'left',
  RIGHT = 'right',
}
