import App from './App'
import { BrowserRouter } from 'react-router-dom'
import ReactDOM from 'react-dom/client'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import keycloakConfig from './config/keycloak.config'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <ReactKeycloakProvider authClient={keycloakConfig}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ReactKeycloakProvider>,
)
