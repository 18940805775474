import './CreatePlan.css'

import {
  BusinessType,
  NewUserState,
  Plan,
  PlanTemplate,
  SearchSegmentedTab,
} from '../../types'
import { Button, DatePicker, Flex, Form, FormProps, Input, message } from 'antd'
import { useEffect, useState } from 'react'

import { InfoCircleOutlined } from '@ant-design/icons'
import NewUserModal from '../../components/NewUserModal/NewUserModal'
import PlanTemplateRadio from '../../components/PlanTemplateRadio/PlanTemplateRadio'
import SearchBusinessTypeAdvice from '../../components/SearchBusinessTypeAdvice/SearchBusinessTypeAdvice'
import SearchBusinessTypeSegmented from '../../components/SearchBusinessTypeSegmented/SearchBusinessTypeSegmented'
import SearchBusinessTypeSelect from '../../components/SearchBusinessTypeSelect/SearchBusinessTypeSelect'
import WelcomeUser from '../../components/WelcomeUser/WelcomeUser'
import companiesStore from '../../stores/companies.store'
import { config } from '../../config'
import { createPlan } from '../../services/plan.service'
import { getTemplateByBusinessType } from '../../utils/planTemplate.util'
import { isEmpty } from 'lodash'
import plansStore from '../../stores/plans.store'
import { updateUserInfo } from '../../services/user.service'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import userStore from '../../stores/user.store'

type PlanForm = Pick<Plan, 'name' | 'template' | 'startDate'>

const CreatePlanPage = () => {
  const { createCompanyPath } = config.path
  const { reportPath } = config.path

  const { user, setUser } = userStore
  const { companies } = companiesStore
  const { unshiftPlan } = plansStore

  const [messageApi, contextHolder] = message.useMessage()

  const navigate = useNavigate()
  const { t } = useTranslation()

  const [currentPlanTemplate, setCurrentPlanTemplate] = useState(
    PlanTemplate.GENERAL_BUSINESS,
  )
  const [planTemplateAi, setPlanTemplateAi] = useState(
    PlanTemplate.GENERAL_BUSINESS,
  )
  const [planTemplateNormal, setPlanTemplateNormal] = useState(
    PlanTemplate.GENERAL_BUSINESS,
  )

  const [valueAi, setValueAi] = useState<BusinessType>()
  const [optionsAi, setOptionsAi] = useState<BusinessType[]>([])

  const [currentTab, setCurrentTab] = useState<SearchSegmentedTab>(
    SearchSegmentedTab.LEFT,
  )

  const [isModalOpen, setIsModalOpen] = useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const [planForm] = Form.useForm()

  useEffect(() => {
    if (isEmpty(companies)) {
      navigate(createCompanyPath)
    } else {
      const currentPlanTemplate = getTemplateByBusinessType(
        companies[0].businessType,
      )

      setCurrentPlanTemplate(currentPlanTemplate)
      planForm.setFieldValue('template', currentPlanTemplate)

      const defaultPlanName = t(companies[0].businessType)
        .substring(6)
        .split(' (')[0]

      planForm.setFieldValue('name', defaultPlanName)
    }
  }, [navigate, companies, planForm, createCompanyPath, t])

  const onFinish: FormProps<PlanForm>['onFinish'] = (planForm: PlanForm) => {
    setIsLoading(true)

    Promise.all([
      createPlan({ ...planForm, companyId: companies[0].companyId }),
      updateUserInfo({
        ...user,
        newUserState: NewUserState.COMPLETE,
      }),
    ]).then((responses) => {
      setUser({
        ...user,
        newUserState: NewUserState.COMPLETE,
      })
      unshiftPlan(responses[0].data)
      navigate(
        `${reportPath}/${companies[0].companyId}/${responses[0].data.companyId}`,
      )
    })
  }

  const onSubmitModal = () => {
    if (currentTab === SearchSegmentedTab.LEFT && valueAi) {
      setCurrentPlanTemplate(planTemplateAi)
      planForm.setFieldValue('template', planTemplateAi)
      if (currentPlanTemplate !== planTemplateAi) {
        messageApi.open({
          type: 'success',
          content: 'เปลี่ยนแผนธุรกิจเรียบร้อยแล้ว',
        })
      }
    } else if (currentTab === SearchSegmentedTab.RIGHT) {
      setCurrentPlanTemplate(planTemplateNormal)
      planForm.setFieldValue('template', planTemplateNormal)
      if (currentPlanTemplate !== planTemplateNormal) {
        messageApi.open({
          type: 'success',
          content: 'เปลี่ยนแผนธุรกิจเรียบร้อยแล้ว',
        })
      }
    }
  }

  const onOpenModal = () => {
    setPlanTemplateNormal(currentPlanTemplate)
    setValueAi(undefined)
    setOptionsAi([])
    setIsModalOpen(true)
  }

  return (
    <Flex className="create-plan-page">
      <WelcomeUser
        form={
          <Form form={planForm} layout="vertical" onFinish={onFinish}>
            <Form.Item
              label="ชื่อแผนธุรกิจ"
              name="name"
              rules={[{ required: true, message: 'โปรดกรอกชื่อแผนธุรกิจ' }]}
            >
              <Input placeholder="กรอกชื่อแผนธุรกิจ" allowClear />
            </Form.Item>
            <Flex
              justify="space-between"
              style={{ lineHeight: '24px', marginBottom: 4 }}
            >
              <span className="font-size-body-2-bold">
                ประเภทแผนธุรกิจที่เราแนะนำ
              </span>
              <span
                onClick={onOpenModal}
                className="font-size-text-link-2-regular font-color-text-icon-detail change-btn"
              >
                เปลี่ยน
              </span>
            </Flex>
            <Form.Item
              name="template"
              rules={[
                { required: true, message: 'โปรดเลือกประเภทของแผนธุรกิจ' },
              ]}
            >
              <PlanTemplateRadio
                value={currentPlanTemplate}
                setValue={setCurrentPlanTemplate}
                options={[currentPlanTemplate]}
              />
            </Form.Item>
            <Form.Item
              label="เลือกเดือนเริ่มต้นรอบบัญชี"
              name="startDate"
              rules={[
                { required: true, message: 'โปรดเลือกเดือนเริ่มต้นรอบบัญชี' },
              ]}
              tooltip={{
                title:
                  'เดือนเริ่มต้นรอบบัญชีจะเป็นตัวกำหนด\nระยะเวลาในรายงานของคุณ',
                icon: <InfoCircleOutlined />,
              }}
            >
              <DatePicker
                picker="month"
                placeholder="เลือกเดือนเริ่มต้นรอบบัญชี"
                format={'MMMM YYYY'}
                style={{ width: '100%' }}
              />
            </Form.Item>
            <Button
              block
              type="primary"
              htmlType="submit"
              size="large"
              loading={isLoading}
            >
              เสร็จสิ้น
            </Button>
          </Form>
        }
        description="ใส่ข้อมูลเกี่ยวกับแผนธุรกิจของคุณ เพื่อให้เราสามารถแนะนำฟีเจอร์ให้เหมาะสมกับธุรกิจคุณ"
        topic="สร้างแผนธุรกิจ"
        companyName={companies[0]?.name}
        backStepUrl={createCompanyPath}
      />
      <NewUserModal
        width={650}
        title="เปลี่ยนประเภทแผนธุรกิจ"
        textBtn="ยืนยันการเปลี่ยน"
        open={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        onSubmitModal={onSubmitModal}
        body={
          <SearchBusinessTypeSegmented
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            tabTitleLeft="ค้นหาด้วย AI"
            tabTitleRight="เลือกจากรายการ"
            tabLeftElement={
              <>
                <SearchBusinessTypeSelect
                  value={valueAi}
                  setValue={setValueAi}
                  options={optionsAi}
                  setOptions={setOptionsAi}
                  placeholder="พิมพ์คำค้นหา เช่น ขายข้าวมันไก่ แบบมีหน้าร้านและขายออนไลน์"
                  notFoundTitle1="พิมพ์คำค้นหาใหม่"
                  notFoundTitle2="โปรดพิมพ์อธิบายเกี่ยวกับธุรกิจของคุณให้ชัดเจนขึ้น"
                  onChangeCallBack={(optionSelected: BusinessType) => {
                    if (optionSelected) {
                      setPlanTemplateAi(
                        getTemplateByBusinessType(optionSelected),
                      )
                    }
                  }}
                />
                {valueAi ? (
                  <Flex style={{ height: 234, marginTop: 16 }} vertical>
                    <PlanTemplateRadio
                      isOnModal={true}
                      value={planTemplateAi}
                      setValue={setPlanTemplateAi}
                      options={['ประเภทแผนธุรกิจที่เราแนะนำ', planTemplateAi]}
                    />
                  </Flex>
                ) : (
                  <SearchBusinessTypeAdvice
                    description1="พิมพ์อธิบายเกี่ยวกับธุรกิจของคุณ"
                    description2="เพื่อให้ AI เราช่วยแนะนำ"
                  />
                )}
              </>
            }
            tabRightElement={
              <Flex
                style={{
                  height: 319,
                  overflowY: 'scroll',
                  overflowX: 'hidden',
                  scrollbarWidth: 'none',
                }}
                vertical
              >
                <PlanTemplateRadio
                  isOnModal={true}
                  value={planTemplateNormal}
                  setValue={setPlanTemplateNormal}
                  options={[
                    'ธุรกิจขายส่ง และการขายปลีก การซ่อมยานยนต์ และจักรยานยนต์ (หมวด G)',
                    PlanTemplate.ONLINE_STORE,
                    PlanTemplate.CLOTHING_STORE,
                    PlanTemplate.COSMETIC_STORE,
                    PlanTemplate.ELECTRICAL_STORE,
                    PlanTemplate.PET_STORE,
                    PlanTemplate.PHARMACY,
                    'ธุรกิจที่พัก และบริการด้านอาหาร (หมวด I)',
                    PlanTemplate.GENERAL_RESTAURANT,
                    PlanTemplate.CAFE,
                    PlanTemplate.LIQUOR_STORE_OR_PUB,
                    PlanTemplate.CATERING,
                    'ธุรกิจการก่อสร้าง (หมวด F)',
                    PlanTemplate.CONSTRUCTION_CONTRACTOR,
                    'ธุรกิจอสังหาริมทรัพย์ (หมวด L)',
                    PlanTemplate.HOUSE_CONDO_APARTMENT_OFFICE_FOR_RENT,
                    PlanTemplate.REAL_ESTATE_AGENT,
                    'ธุรกิจอื่นๆ (ที่ไม่ได้อยู่ในรายการ)',
                    PlanTemplate.GENERAL_BUSINESS,
                  ]}
                />
              </Flex>
            }
          />
        }
      />
      {contextHolder}
    </Flex>
  )
}

export default CreatePlanPage
