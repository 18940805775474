import axios, { AxiosResponse } from 'axios'

import { Plan } from '../types'

export const createPlan = async (
  data: Pick<Plan, 'companyId' | 'name' | 'startDate' | 'template'>,
): Promise<AxiosResponse<Plan>> => {
  return axios.post('v1/plan', {
    ...data,
    timezoneOffset: new Date(data.startDate).getTimezoneOffset(),
  })
}

export const updatePlan = async (
  data: Pick<Plan, 'planId' | 'name' | 'startDate' | 'template'>,
): Promise<AxiosResponse<Plan>> => {
  return axios.put('v1/plan', {
    ...data,
    timezoneOffset: new Date(data.startDate).getTimezoneOffset(),
  })
}

export const getAllPlans = async (): Promise<AxiosResponse<Plan[]>> => {
  return axios.get('v1/plan')
}

export const deletePlan = async (
  planId: string,
): Promise<AxiosResponse<void>> => {
  return axios.delete('v1/Plan', { params: { planId } })
}
