export type Company = {
  companyId: string
  userId: string
  name: string
  businessType: BusinessType
  country: Country
  currency: Currency
  createdAt: string
  updatedAt: string
}

export enum Country {
  TH = 'TH',
}

export enum Currency {
  THB = 'THB',
}

export const BusinessTypes: BusinessType[] = [
  '01111',
  '01112',
  '01113',
  '01114',
  '01115',
  '01121',
  '01122',
  '01131',
  '01132',
  '01133',
  '01134',
  '01135',
  '01136',
  '01139',
  '01140',
  '01150',
  '01161',
  '01169',
  '01191',
  '01192',
  '01193',
  '01194',
  '01199',
  '01210',
  '01221',
  '01222',
  '01223',
  '01224',
  '01225',
  '01226',
  '01227',
  '01228',
  '01229',
  '01231',
  '01239',
  '01241',
  '01249',
  '01251',
  '01252',
  '01259',
  '01261',
  '01262',
  '01269',
  '01271',
  '01272',
  '01279',
  '01281',
  '01282',
  '01289',
  '01291',
  '01292',
  '01299',
  '01301',
  '01302',
  '01411',
  '01412',
  '01419',
  '01420',
  '01430',
  '01441',
  '01442',
  '01450',
  '01461',
  '01462',
  '01463',
  '01469',
  '01491',
  '01492',
  '01493',
  '01494',
  '01495',
  '01496',
  '01499',
  '01500',
  '01611',
  '01612',
  '01619',
  '01621',
  '01629',
  '01630',
  '01640',
  '01700',
  '02100',
  '02200',
  '02300',
  '02400',
  '03111',
  '03112',
  '03113',
  '03114',
  '03115',
  '03119',
  '03121',
  '03122',
  '03129',
  '03211',
  '03212',
  '03213',
  '03214',
  '03219',
  '03221',
  '03222',
  '03223',
  '03224',
  '03225',
  '03229',
  '05100',
  '05200',
  '06100',
  '06200',
  '07100',
  '07210',
  '07291',
  '07292',
  '07299',
  '07300',
  '08101',
  '08102',
  '08103',
  '08104',
  '08910',
  '08920',
  '08931',
  '08932',
  '08991',
  '08999',
  '09100',
  '09900',
  '10111',
  '10120',
  '10131',
  '10132',
  '10133',
  '10134',
  '10139',
  '10211',
  '10212',
  '10221',
  '10222',
  '10291',
  '10292',
  '10293',
  '10294',
  '10295',
  '10299',
  '10301',
  '10302',
  '10303',
  '10304',
  '10305',
  '10306',
  '10307',
  '10309',
  '10411',
  '10412',
  '10413',
  '10414',
  '10419',
  '10420',
  '10491',
  '10499',
  '10501',
  '10502',
  '10503',
  '10504',
  '10505',
  '10509',
  '10611',
  '10612',
  '10613',
  '10614',
  '10615',
  '10616',
  '10617',
  '10619',
  '10621',
  '10622',
  '10623',
  '10629',
  '10711',
  '10712',
  '10713',
  '10721',
  '10722',
  '10723',
  '10731',
  '10732',
  '10733',
  '10734',
  '10739',
  '10741',
  '10742',
  '10743',
  '10749',
  '10751',
  '10752',
  '10761',
  '10762',
  '10769',
  '10771',
  '10772',
  '10773',
  '10774',
  '10775',
  '10779',
  '10791',
  '10792',
  '10793',
  '10794',
  '10795',
  '10796',
  '10799',
  '10801',
  '10802',
  '11011',
  '11012',
  '11021',
  '11022',
  '11029',
  '11030',
  '11041',
  '11042',
  '11043',
  '11044',
  '11049',
  '12001',
  '12002',
  '12009',
  '13111',
  '13112',
  '13113',
  '13121',
  '13122',
  '13129',
  '13131',
  '13132',
  '13139',
  '13910',
  '13921',
  '13922',
  '13929',
  '13930',
  '13940',
  '13991',
  '13992',
  '13999',
  '14111',
  '14112',
  '14113',
  '14114',
  '14115',
  '14116',
  '14120',
  '14131',
  '14139',
  '14200',
  '14301',
  '14302',
  '15110',
  '15121',
  '15122',
  '15129',
  '15201',
  '15202',
  '15203',
  '15204',
  '15209',
  '16101',
  '16102',
  '16210',
  '16220',
  '16230',
  '16291',
  '16292',
  '16299',
  '17011',
  '17012',
  '17020',
  '17091',
  '17092',
  '17099',
  '18111',
  '18112',
  '18119',
  '18121',
  '18122',
  '18200',
  '19100',
  '19201',
  '19202',
  '19209',
  '20111',
  '20112',
  '20113',
  '20114',
  '20115',
  '20121',
  '20122',
  '20131',
  '20132',
  '20210',
  '20221',
  '20222',
  '20231',
  '20232',
  '20291',
  '20292',
  '20293',
  '20294',
  '20299',
  '20300',
  '21001',
  '21002',
  '22111',
  '22112',
  '22191',
  '22192',
  '22193',
  '22199',
  '22210',
  '22220',
  '22230',
  '22291',
  '22292',
  '22299',
  '23101',
  '23102',
  '23103',
  '23109',
  '23910',
  '23921',
  '23922',
  '23923',
  '23929',
  '23931',
  '23932',
  '23933',
  '23939',
  '23941',
  '23942',
  '23951',
  '23952',
  '23953',
  '23954',
  '23959',
  '23961',
  '23969',
  '23991',
  '23992',
  '23999',
  '24101',
  '24102',
  '24103',
  '24109',
  '24201',
  '24202',
  '24203',
  '24204',
  '24209',
  '24311',
  '24312',
  '24320',
  '25111',
  '25112',
  '25113',
  '25119',
  '25121',
  '25122',
  '25129',
  '25130',
  '25201',
  '25209',
  '25910',
  '25921',
  '25922',
  '25931',
  '25932',
  '25939',
  '25941',
  '25949',
  '25951',
  '25952',
  '25991',
  '25992',
  '25993',
  '25999',
  '26101',
  '26102',
  '26103',
  '26104',
  '26109',
  '26201',
  '26202',
  '26203',
  '26209',
  '26301',
  '26302',
  '26303',
  '26309',
  '26401',
  '26402',
  '26403',
  '26409',
  '26511',
  '26512',
  '26521',
  '26529',
  '26600',
  '26701',
  '26702',
  '26703',
  '26800',
  '27101',
  '27102',
  '27103',
  '27200',
  '27310',
  '27320',
  '27330',
  '27401',
  '27409',
  '27501',
  '27502',
  '27503',
  '27901',
  '27902',
  '27909',
  '28110',
  '28120',
  '28131',
  '28132',
  '28140',
  '28150',
  '28160',
  '28170',
  '28180',
  '28191',
  '28199',
  '28211',
  '28219',
  '28221',
  '28229',
  '28230',
  '28240',
  '28250',
  '28261',
  '28269',
  '28291',
  '28292',
  '28299',
  '29101',
  '29102',
  '29103',
  '29104',
  '29109',
  '29201',
  '29202',
  '29203',
  '29301',
  '29302',
  '29309',
  '30110',
  '30120',
  '30200',
  '30300',
  '30400',
  '30911',
  '30912',
  '30921',
  '30922',
  '30990',
  '31001',
  '31002',
  '31003',
  '31009',
  '32111',
  '32112',
  '32113',
  '32120',
  '32201',
  '32209',
  '32301',
  '32302',
  '32303',
  '32309',
  '32401',
  '32402',
  '32409',
  '32501',
  '32502',
  '32901',
  '32902',
  '32903',
  '32904',
  '32909',
  '33110',
  '33121',
  '33122',
  '33131',
  '33132',
  '33133',
  '33141',
  '33142',
  '33149',
  '33151',
  '33152',
  '33159',
  '33190',
  '33200',
  '35101',
  '35102',
  '35201',
  '35202',
  '35301',
  '35302',
  '36001',
  '36002',
  '36003',
  '36009',
  '37000',
  '38110',
  '38120',
  '38211',
  '38212',
  '38213',
  '38219',
  '38221',
  '38222',
  '38300',
  '39000',
  '41001',
  '41002',
  '42101',
  '42102',
  '42201',
  '42202',
  '42901',
  '42909',
  '43110',
  '43120',
  '43210',
  '43221',
  '43222',
  '43223',
  '43291',
  '43299',
  '43301',
  '43302',
  '43303',
  '43309',
  '43901',
  '43909',
  '45101',
  '45102',
  '45103',
  '45104',
  '45201',
  '45202',
  '45203',
  '45301',
  '45302',
  '45303',
  '45401',
  '45402',
  '45403',
  '45404',
  '45405',
  '46101',
  '46102',
  '46103',
  '46104',
  '46105',
  '46106',
  '46107',
  '46108',
  '46109',
  '46201',
  '46202',
  '46203',
  '46204',
  '46205',
  '46206',
  '46209',
  '46311',
  '46312',
  '46313',
  '46314',
  '46315',
  '46316',
  '46317',
  '46318',
  '46319',
  '46321',
  '46322',
  '46323',
  '46411',
  '46412',
  '46413',
  '46414',
  '46415',
  '46421',
  '46422',
  '46431',
  '46432',
  '46433',
  '46434',
  '46439',
  '46441',
  '46442',
  '46443',
  '46491',
  '46492',
  '46493',
  '46494',
  '46495',
  '46499',
  '46510',
  '46521',
  '46522',
  '46530',
  '46591',
  '46592',
  '46593',
  '46594',
  '46599',
  '46611',
  '46612',
  '46613',
  '46614',
  '46621',
  '46622',
  '46631',
  '46632',
  '46633',
  '46634',
  '46639',
  '46691',
  '46692',
  '46693',
  '46694',
  '46695',
  '46699',
  '46900',
  '47111',
  '47112',
  '47113',
  '47114',
  '47190',
  '47211',
  '47212',
  '47213',
  '47214',
  '47215',
  '47219',
  '47221',
  '47222',
  '47230',
  '47300',
  '47411',
  '47412',
  '47413',
  '47420',
  '47511',
  '47512',
  '47513',
  '47521',
  '47522',
  '47523',
  '47524',
  '47525',
  '47530',
  '47591',
  '47592',
  '47593',
  '47594',
  '47595',
  '47599',
  '47611',
  '47612',
  '47620',
  '47630',
  '47640',
  '47691',
  '47699',
  '47711',
  '47712',
  '47713',
  '47721',
  '47722',
  '47723',
  '47731',
  '47732',
  '47733',
  '47734',
  '47735',
  '47739',
  '47741',
  '47742',
  '47743',
  '47744',
  '47745',
  '47749',
  '47811',
  '47812',
  '47813',
  '47821',
  '47822',
  '47891',
  '47892',
  '47893',
  '47894',
  '47895',
  '47896',
  '47897',
  '47899',
  '47911',
  '47912',
  '47991',
  '47999',
  '49110',
  '49120',
  '49201',
  '49202',
  '49203',
  '49204',
  '49209',
  '49310',
  '49321',
  '49322',
  '49329',
  '49331',
  '49332',
  '49333',
  '49334',
  '49339',
  '49400',
  '50111',
  '50112',
  '50119',
  '50121',
  '50122',
  '50211',
  '50212',
  '50219',
  '50221',
  '50222',
  '51101',
  '51102',
  '51201',
  '51202',
  '52101',
  '52102',
  '52109',
  '52211',
  '52212',
  '52213',
  '52214',
  '52219',
  '52221',
  '52229',
  '52231',
  '52239',
  '52241',
  '52242',
  '52291',
  '52292',
  '52293',
  '52299',
  '53100',
  '53200',
  '55101',
  '55102',
  '55103',
  '55109',
  '55200',
  '55901',
  '55909',
  '56101',
  '56102',
  '56103',
  '56210',
  '56291',
  '56292',
  '56299',
  '56301',
  '56302',
  '56303',
  '56304',
  '58111',
  '58112',
  '58113',
  '58114',
  '58121',
  '58122',
  '58131',
  '58132',
  '58133',
  '58134',
  '58191',
  '58192',
  '58193',
  '58201',
  '58202',
  '58203',
  '59111',
  '59112',
  '59121',
  '59122',
  '59129',
  '59131',
  '59132',
  '59140',
  '59201',
  '59202',
  '59203',
  '60101',
  '60102',
  '60201',
  '60202',
  '60203',
  '61101',
  '61102',
  '61109',
  '61201',
  '61202',
  '61209',
  '61301',
  '61302',
  '61900',
  '62011',
  '62012',
  '62021',
  '62022',
  '62023',
  '62090',
  '63111',
  '63112',
  '63120',
  '63911',
  '63912',
  '63990',
  '64110',
  '64191',
  '64192',
  '64193',
  '64194',
  '64195',
  '64201',
  '64202',
  '64301',
  '64302',
  '64309',
  '64911',
  '64912',
  '64913',
  '64921',
  '64922',
  '64923',
  '64924',
  '64925',
  '64929',
  '64991',
  '64992',
  '64999',
  '65110',
  '65120',
  '65200',
  '65300',
  '66111',
  '66112',
  '66113',
  '66121',
  '66122',
  '66123',
  '66191',
  '66192',
  '66193',
  '66199',
  '66210',
  '66221',
  '66222',
  '66290',
  '66301',
  '66302',
  '68101',
  '68102',
  '68103',
  '68104',
  '68201',
  '68202',
  '69100',
  '69200',
  '70100',
  '70201',
  '70202',
  '70209',
  '71101',
  '71102',
  '71103',
  '71201',
  '71202',
  '71209',
  '72101',
  '72102',
  '72109',
  '72200',
  '73101',
  '73102',
  '73200',
  '74101',
  '74109',
  '74200',
  '74901',
  '74902',
  '74909',
  '75000',
  '77101',
  '77109',
  '77210',
  '77220',
  '77291',
  '77299',
  '77301',
  '77302',
  '77303',
  '77304',
  '77305',
  '77306',
  '77309',
  '77400',
  '78101',
  '78109',
  '78200',
  '78300',
  '79110',
  '79120',
  '79901',
  '79909',
  '80100',
  '80200',
  '80300',
  '81100',
  '81210',
  '81291',
  '81292',
  '81293',
  '81299',
  '81300',
  '82110',
  '82191',
  '82199',
  '82200',
  '82301',
  '82302',
  '82911',
  '82912',
  '82920',
  '82990',
  '84111',
  '84114',
  '84121',
  '84122',
  '84123',
  '84124',
  '84131',
  '84132',
  '84133',
  '84134',
  '84135',
  '84136',
  '84137',
  '84210',
  '84220',
  '84231',
  '84232',
  '84233',
  '84300',
  '85101',
  '85102',
  '85103',
  '85211',
  '85212',
  '85213',
  '85220',
  '85301',
  '85302',
  '85303',
  '85410',
  '85421',
  '85422',
  '85423',
  '85429',
  '85491',
  '85492',
  '85493',
  '85494',
  '85495',
  '85496',
  '85497',
  '85499',
  '85500',
  '85601',
  '85602',
  '86101',
  '86102',
  '86201',
  '86202',
  '86203',
  '86901',
  '86902',
  '86903',
  '86909',
  '87100',
  '87201',
  '87202',
  '87203',
  '87301',
  '87302',
  '87303',
  '87901',
  '87902',
  '87909',
  '88101',
  '88102',
  '88901',
  '88909',
  '90001',
  '90002',
  '91011',
  '91012',
  '91021',
  '91022',
  '91031',
  '91032',
  '92001',
  '92009',
  '93111',
  '93112',
  '93120',
  '93191',
  '93192',
  '93199',
  '93210',
  '93291',
  '93292',
  '93293',
  '93299',
  '94110',
  '94120',
  '94200',
  '94910',
  '94920',
  '94991',
  '94992',
  '94993',
  '94994',
  '94999',
  '95110',
  '95120',
  '95210',
  '95220',
  '95230',
  '95240',
  '95291',
  '95292',
  '95293',
  '95294',
  '95295',
  '95299',
  '96101',
  '96102',
  '96103',
  '96104',
  '96109',
  '96201',
  '96202',
  '96203',
  '96301',
  '96302',
  '96303',
  '96304',
  '96305',
  '96309',
  '97000',
  '98100',
  '98200',
  '99001',
  '99009',
]

export type BusinessType =
  | '01111'
  | '01112'
  | '01113'
  | '01114'
  | '01115'
  | '01121'
  | '01122'
  | '01131'
  | '01132'
  | '01133'
  | '01134'
  | '01135'
  | '01136'
  | '01139'
  | '01140'
  | '01150'
  | '01161'
  | '01169'
  | '01191'
  | '01192'
  | '01193'
  | '01194'
  | '01199'
  | '01210'
  | '01221'
  | '01222'
  | '01223'
  | '01224'
  | '01225'
  | '01226'
  | '01227'
  | '01228'
  | '01229'
  | '01231'
  | '01239'
  | '01241'
  | '01249'
  | '01251'
  | '01252'
  | '01259'
  | '01261'
  | '01262'
  | '01269'
  | '01271'
  | '01272'
  | '01279'
  | '01281'
  | '01282'
  | '01289'
  | '01291'
  | '01292'
  | '01299'
  | '01301'
  | '01302'
  | '01411'
  | '01412'
  | '01419'
  | '01420'
  | '01430'
  | '01441'
  | '01442'
  | '01450'
  | '01461'
  | '01462'
  | '01463'
  | '01469'
  | '01491'
  | '01492'
  | '01493'
  | '01494'
  | '01495'
  | '01496'
  | '01499'
  | '01500'
  | '01611'
  | '01612'
  | '01619'
  | '01621'
  | '01629'
  | '01630'
  | '01640'
  | '01700'
  | '02100'
  | '02200'
  | '02300'
  | '02400'
  | '03111'
  | '03112'
  | '03113'
  | '03114'
  | '03115'
  | '03119'
  | '03121'
  | '03122'
  | '03129'
  | '03211'
  | '03212'
  | '03213'
  | '03214'
  | '03219'
  | '03221'
  | '03222'
  | '03223'
  | '03224'
  | '03225'
  | '03229'
  | '05100'
  | '05200'
  | '06100'
  | '06200'
  | '07100'
  | '07210'
  | '07291'
  | '07292'
  | '07299'
  | '07300'
  | '08101'
  | '08102'
  | '08103'
  | '08104'
  | '08910'
  | '08920'
  | '08931'
  | '08932'
  | '08991'
  | '08999'
  | '09100'
  | '09900'
  | '10111'
  | '10120'
  | '10131'
  | '10132'
  | '10133'
  | '10134'
  | '10139'
  | '10211'
  | '10212'
  | '10221'
  | '10222'
  | '10291'
  | '10292'
  | '10293'
  | '10294'
  | '10295'
  | '10299'
  | '10301'
  | '10302'
  | '10303'
  | '10304'
  | '10305'
  | '10306'
  | '10307'
  | '10309'
  | '10411'
  | '10412'
  | '10413'
  | '10414'
  | '10419'
  | '10420'
  | '10491'
  | '10499'
  | '10501'
  | '10502'
  | '10503'
  | '10504'
  | '10505'
  | '10509'
  | '10611'
  | '10612'
  | '10613'
  | '10614'
  | '10615'
  | '10616'
  | '10617'
  | '10619'
  | '10621'
  | '10622'
  | '10623'
  | '10629'
  | '10711'
  | '10712'
  | '10713'
  | '10721'
  | '10722'
  | '10723'
  | '10731'
  | '10732'
  | '10733'
  | '10734'
  | '10739'
  | '10741'
  | '10742'
  | '10743'
  | '10749'
  | '10751'
  | '10752'
  | '10761'
  | '10762'
  | '10769'
  | '10771'
  | '10772'
  | '10773'
  | '10774'
  | '10775'
  | '10779'
  | '10791'
  | '10792'
  | '10793'
  | '10794'
  | '10795'
  | '10796'
  | '10799'
  | '10801'
  | '10802'
  | '11011'
  | '11012'
  | '11021'
  | '11022'
  | '11029'
  | '11030'
  | '11041'
  | '11042'
  | '11043'
  | '11044'
  | '11049'
  | '12001'
  | '12002'
  | '12009'
  | '13111'
  | '13112'
  | '13113'
  | '13121'
  | '13122'
  | '13129'
  | '13131'
  | '13132'
  | '13139'
  | '13910'
  | '13921'
  | '13922'
  | '13929'
  | '13930'
  | '13940'
  | '13991'
  | '13992'
  | '13999'
  | '14111'
  | '14112'
  | '14113'
  | '14114'
  | '14115'
  | '14116'
  | '14120'
  | '14131'
  | '14139'
  | '14200'
  | '14301'
  | '14302'
  | '15110'
  | '15121'
  | '15122'
  | '15129'
  | '15201'
  | '15202'
  | '15203'
  | '15204'
  | '15209'
  | '16101'
  | '16102'
  | '16210'
  | '16220'
  | '16230'
  | '16291'
  | '16292'
  | '16299'
  | '17011'
  | '17012'
  | '17020'
  | '17091'
  | '17092'
  | '17099'
  | '18111'
  | '18112'
  | '18119'
  | '18121'
  | '18122'
  | '18200'
  | '19100'
  | '19201'
  | '19202'
  | '19209'
  | '20111'
  | '20112'
  | '20113'
  | '20114'
  | '20115'
  | '20121'
  | '20122'
  | '20131'
  | '20132'
  | '20210'
  | '20221'
  | '20222'
  | '20231'
  | '20232'
  | '20291'
  | '20292'
  | '20293'
  | '20294'
  | '20299'
  | '20300'
  | '21001'
  | '21002'
  | '22111'
  | '22112'
  | '22191'
  | '22192'
  | '22193'
  | '22199'
  | '22210'
  | '22220'
  | '22230'
  | '22291'
  | '22292'
  | '22299'
  | '23101'
  | '23102'
  | '23103'
  | '23109'
  | '23910'
  | '23921'
  | '23922'
  | '23923'
  | '23929'
  | '23931'
  | '23932'
  | '23933'
  | '23939'
  | '23941'
  | '23942'
  | '23951'
  | '23952'
  | '23953'
  | '23954'
  | '23959'
  | '23961'
  | '23969'
  | '23991'
  | '23992'
  | '23999'
  | '24101'
  | '24102'
  | '24103'
  | '24109'
  | '24201'
  | '24202'
  | '24203'
  | '24204'
  | '24209'
  | '24311'
  | '24312'
  | '24320'
  | '25111'
  | '25112'
  | '25113'
  | '25119'
  | '25121'
  | '25122'
  | '25129'
  | '25130'
  | '25201'
  | '25209'
  | '25910'
  | '25921'
  | '25922'
  | '25931'
  | '25932'
  | '25939'
  | '25941'
  | '25949'
  | '25951'
  | '25952'
  | '25991'
  | '25992'
  | '25993'
  | '25999'
  | '26101'
  | '26102'
  | '26103'
  | '26104'
  | '26109'
  | '26201'
  | '26202'
  | '26203'
  | '26209'
  | '26301'
  | '26302'
  | '26303'
  | '26309'
  | '26401'
  | '26402'
  | '26403'
  | '26409'
  | '26511'
  | '26512'
  | '26521'
  | '26529'
  | '26600'
  | '26701'
  | '26702'
  | '26703'
  | '26800'
  | '27101'
  | '27102'
  | '27103'
  | '27200'
  | '27310'
  | '27320'
  | '27330'
  | '27401'
  | '27409'
  | '27501'
  | '27502'
  | '27503'
  | '27901'
  | '27902'
  | '27909'
  | '28110'
  | '28120'
  | '28131'
  | '28132'
  | '28140'
  | '28150'
  | '28160'
  | '28170'
  | '28180'
  | '28191'
  | '28199'
  | '28211'
  | '28219'
  | '28221'
  | '28229'
  | '28230'
  | '28240'
  | '28250'
  | '28261'
  | '28269'
  | '28291'
  | '28292'
  | '28299'
  | '29101'
  | '29102'
  | '29103'
  | '29104'
  | '29109'
  | '29201'
  | '29202'
  | '29203'
  | '29301'
  | '29302'
  | '29309'
  | '30110'
  | '30120'
  | '30200'
  | '30300'
  | '30400'
  | '30911'
  | '30912'
  | '30921'
  | '30922'
  | '30990'
  | '31001'
  | '31002'
  | '31003'
  | '31009'
  | '32111'
  | '32112'
  | '32113'
  | '32120'
  | '32201'
  | '32209'
  | '32301'
  | '32302'
  | '32303'
  | '32309'
  | '32401'
  | '32402'
  | '32409'
  | '32501'
  | '32502'
  | '32901'
  | '32902'
  | '32903'
  | '32904'
  | '32909'
  | '33110'
  | '33121'
  | '33122'
  | '33131'
  | '33132'
  | '33133'
  | '33141'
  | '33142'
  | '33149'
  | '33151'
  | '33152'
  | '33159'
  | '33190'
  | '33200'
  | '35101'
  | '35102'
  | '35201'
  | '35202'
  | '35301'
  | '35302'
  | '36001'
  | '36002'
  | '36003'
  | '36009'
  | '37000'
  | '38110'
  | '38120'
  | '38211'
  | '38212'
  | '38213'
  | '38219'
  | '38221'
  | '38222'
  | '38300'
  | '39000'
  | '41001'
  | '41002'
  | '42101'
  | '42102'
  | '42201'
  | '42202'
  | '42901'
  | '42909'
  | '43110'
  | '43120'
  | '43210'
  | '43221'
  | '43222'
  | '43223'
  | '43291'
  | '43299'
  | '43301'
  | '43302'
  | '43303'
  | '43309'
  | '43901'
  | '43909'
  | '45101'
  | '45102'
  | '45103'
  | '45104'
  | '45201'
  | '45202'
  | '45203'
  | '45301'
  | '45302'
  | '45303'
  | '45401'
  | '45402'
  | '45403'
  | '45404'
  | '45405'
  | '46101'
  | '46102'
  | '46103'
  | '46104'
  | '46105'
  | '46106'
  | '46107'
  | '46108'
  | '46109'
  | '46201'
  | '46202'
  | '46203'
  | '46204'
  | '46205'
  | '46206'
  | '46209'
  | '46311'
  | '46312'
  | '46313'
  | '46314'
  | '46315'
  | '46316'
  | '46317'
  | '46318'
  | '46319'
  | '46321'
  | '46322'
  | '46323'
  | '46411'
  | '46412'
  | '46413'
  | '46414'
  | '46415'
  | '46421'
  | '46422'
  | '46431'
  | '46432'
  | '46433'
  | '46434'
  | '46439'
  | '46441'
  | '46442'
  | '46443'
  | '46491'
  | '46492'
  | '46493'
  | '46494'
  | '46495'
  | '46499'
  | '46510'
  | '46521'
  | '46522'
  | '46530'
  | '46591'
  | '46592'
  | '46593'
  | '46594'
  | '46599'
  | '46611'
  | '46612'
  | '46613'
  | '46614'
  | '46621'
  | '46622'
  | '46631'
  | '46632'
  | '46633'
  | '46634'
  | '46639'
  | '46691'
  | '46692'
  | '46693'
  | '46694'
  | '46695'
  | '46699'
  | '46900'
  | '47111'
  | '47112'
  | '47113'
  | '47114'
  | '47190'
  | '47211'
  | '47212'
  | '47213'
  | '47214'
  | '47215'
  | '47219'
  | '47221'
  | '47222'
  | '47230'
  | '47300'
  | '47411'
  | '47412'
  | '47413'
  | '47420'
  | '47511'
  | '47512'
  | '47513'
  | '47521'
  | '47522'
  | '47523'
  | '47524'
  | '47525'
  | '47530'
  | '47591'
  | '47592'
  | '47593'
  | '47594'
  | '47595'
  | '47599'
  | '47611'
  | '47612'
  | '47620'
  | '47630'
  | '47640'
  | '47691'
  | '47699'
  | '47711'
  | '47712'
  | '47713'
  | '47721'
  | '47722'
  | '47723'
  | '47731'
  | '47732'
  | '47733'
  | '47734'
  | '47735'
  | '47739'
  | '47741'
  | '47742'
  | '47743'
  | '47744'
  | '47745'
  | '47749'
  | '47811'
  | '47812'
  | '47813'
  | '47821'
  | '47822'
  | '47891'
  | '47892'
  | '47893'
  | '47894'
  | '47895'
  | '47896'
  | '47897'
  | '47899'
  | '47911'
  | '47912'
  | '47991'
  | '47999'
  | '49110'
  | '49120'
  | '49201'
  | '49202'
  | '49203'
  | '49204'
  | '49209'
  | '49310'
  | '49321'
  | '49322'
  | '49329'
  | '49331'
  | '49332'
  | '49333'
  | '49334'
  | '49339'
  | '49400'
  | '50111'
  | '50112'
  | '50119'
  | '50121'
  | '50122'
  | '50211'
  | '50212'
  | '50219'
  | '50221'
  | '50222'
  | '51101'
  | '51102'
  | '51201'
  | '51202'
  | '52101'
  | '52102'
  | '52109'
  | '52211'
  | '52212'
  | '52213'
  | '52214'
  | '52219'
  | '52221'
  | '52229'
  | '52231'
  | '52239'
  | '52241'
  | '52242'
  | '52291'
  | '52292'
  | '52293'
  | '52299'
  | '53100'
  | '53200'
  | '55101'
  | '55102'
  | '55103'
  | '55109'
  | '55200'
  | '55901'
  | '55909'
  | '56101'
  | '56102'
  | '56103'
  | '56210'
  | '56291'
  | '56292'
  | '56299'
  | '56301'
  | '56302'
  | '56303'
  | '56304'
  | '58111'
  | '58112'
  | '58113'
  | '58114'
  | '58121'
  | '58122'
  | '58131'
  | '58132'
  | '58133'
  | '58134'
  | '58191'
  | '58192'
  | '58193'
  | '58201'
  | '58202'
  | '58203'
  | '59111'
  | '59112'
  | '59121'
  | '59122'
  | '59129'
  | '59131'
  | '59132'
  | '59140'
  | '59201'
  | '59202'
  | '59203'
  | '60101'
  | '60102'
  | '60201'
  | '60202'
  | '60203'
  | '61101'
  | '61102'
  | '61109'
  | '61201'
  | '61202'
  | '61209'
  | '61301'
  | '61302'
  | '61900'
  | '62011'
  | '62012'
  | '62021'
  | '62022'
  | '62023'
  | '62090'
  | '63111'
  | '63112'
  | '63120'
  | '63911'
  | '63912'
  | '63990'
  | '64110'
  | '64191'
  | '64192'
  | '64193'
  | '64194'
  | '64195'
  | '64201'
  | '64202'
  | '64301'
  | '64302'
  | '64309'
  | '64911'
  | '64912'
  | '64913'
  | '64921'
  | '64922'
  | '64923'
  | '64924'
  | '64925'
  | '64929'
  | '64991'
  | '64992'
  | '64999'
  | '65110'
  | '65120'
  | '65200'
  | '65300'
  | '66111'
  | '66112'
  | '66113'
  | '66121'
  | '66122'
  | '66123'
  | '66191'
  | '66192'
  | '66193'
  | '66199'
  | '66210'
  | '66221'
  | '66222'
  | '66290'
  | '66301'
  | '66302'
  | '68101'
  | '68102'
  | '68103'
  | '68104'
  | '68201'
  | '68202'
  | '69100'
  | '69200'
  | '70100'
  | '70201'
  | '70202'
  | '70209'
  | '71101'
  | '71102'
  | '71103'
  | '71201'
  | '71202'
  | '71209'
  | '72101'
  | '72102'
  | '72109'
  | '72200'
  | '73101'
  | '73102'
  | '73200'
  | '74101'
  | '74109'
  | '74200'
  | '74901'
  | '74902'
  | '74909'
  | '75000'
  | '77101'
  | '77109'
  | '77210'
  | '77220'
  | '77291'
  | '77299'
  | '77301'
  | '77302'
  | '77303'
  | '77304'
  | '77305'
  | '77306'
  | '77309'
  | '77400'
  | '78101'
  | '78109'
  | '78200'
  | '78300'
  | '79110'
  | '79120'
  | '79901'
  | '79909'
  | '80100'
  | '80200'
  | '80300'
  | '81100'
  | '81210'
  | '81291'
  | '81292'
  | '81293'
  | '81299'
  | '81300'
  | '82110'
  | '82191'
  | '82199'
  | '82200'
  | '82301'
  | '82302'
  | '82911'
  | '82912'
  | '82920'
  | '82990'
  | '84111'
  | '84114'
  | '84121'
  | '84122'
  | '84123'
  | '84124'
  | '84131'
  | '84132'
  | '84133'
  | '84134'
  | '84135'
  | '84136'
  | '84137'
  | '84210'
  | '84220'
  | '84231'
  | '84232'
  | '84233'
  | '84300'
  | '85101'
  | '85102'
  | '85103'
  | '85211'
  | '85212'
  | '85213'
  | '85220'
  | '85301'
  | '85302'
  | '85303'
  | '85410'
  | '85421'
  | '85422'
  | '85423'
  | '85429'
  | '85491'
  | '85492'
  | '85493'
  | '85494'
  | '85495'
  | '85496'
  | '85497'
  | '85499'
  | '85500'
  | '85601'
  | '85602'
  | '86101'
  | '86102'
  | '86201'
  | '86202'
  | '86203'
  | '86901'
  | '86902'
  | '86903'
  | '86909'
  | '87100'
  | '87201'
  | '87202'
  | '87203'
  | '87301'
  | '87302'
  | '87303'
  | '87901'
  | '87902'
  | '87909'
  | '88101'
  | '88102'
  | '88901'
  | '88909'
  | '90001'
  | '90002'
  | '91011'
  | '91012'
  | '91021'
  | '91022'
  | '91031'
  | '91032'
  | '92001'
  | '92009'
  | '93111'
  | '93112'
  | '93120'
  | '93191'
  | '93192'
  | '93199'
  | '93210'
  | '93291'
  | '93292'
  | '93293'
  | '93299'
  | '94110'
  | '94120'
  | '94200'
  | '94910'
  | '94920'
  | '94991'
  | '94992'
  | '94993'
  | '94994'
  | '94999'
  | '95110'
  | '95120'
  | '95210'
  | '95220'
  | '95230'
  | '95240'
  | '95291'
  | '95292'
  | '95293'
  | '95294'
  | '95295'
  | '95299'
  | '96101'
  | '96102'
  | '96103'
  | '96104'
  | '96109'
  | '96201'
  | '96202'
  | '96203'
  | '96301'
  | '96302'
  | '96303'
  | '96304'
  | '96305'
  | '96309'
  | '97000'
  | '98100'
  | '98200'
  | '99001'
  | '99009'
