import { NewUserState, User } from '../types'

import { makeAutoObservable } from 'mobx'

class UserStore {
  user: User = {
    email: '',
    firstName: '',
    lastName: '',
    picture: '',
    newUserState: NewUserState.COMPLETE,
  }

  constructor() {
    makeAutoObservable(this)
  }

  setUser = (user: User) => {
    this.user = user
  }
}
const userStore = new UserStore()
export default userStore
