import { PartnerIntegration, Platform } from '../types/partner-integration.type'
import axios, { AxiosResponse } from 'axios'

export const getUriConnection = async (
  platform: Platform,
  state: string,
): Promise<AxiosResponse<{ uri: string }>> => {
  return axios.get('v1/partner-integration/uri-connection', {
    params: { platform, state },
  })
}

export const createPartnerIntegration = async (
  data: Pick<PartnerIntegration, 'companyId' | 'platform' | 'shopId'>,
): Promise<AxiosResponse<PartnerIntegration>> => {
  return axios.post('v1/partner-integration', data)
}

export const updatePartnerIntegration = async (
  partnerIntegrationId: string,
  shopName: string,
): Promise<AxiosResponse<PartnerIntegration>> => {
  return axios.patch('v1/partner-integration', {
    partnerIntegrationId,
    shopName,
  })
}

export const getAllPartnerIntegrationsByCompany = async (
  companyId: string,
): Promise<AxiosResponse<PartnerIntegration[]>> => {
  return axios.get('v1/partner-integration', { params: { companyId } })
}

export const deletePartnerIntegration = async (
  partnerIntegrationId: string,
): Promise<AxiosResponse<{ deletedCount: number }>> => {
  return axios.delete(`v1/partner-integration/${partnerIntegrationId}`)
}
