import { Route, Routes, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'

import LoadingBrandColor from '../LoadingBrandColor/LoadingBrandColor'
import NewUserRoute from './NewUser.route'
import { NewUserState } from '../../types'
import SubRoute from './Sub.route'
import companiesStore from '../../stores/companies.store'
import { config } from '../../config'
import { getAllCompanies } from '../../services/company.service'
import { getAllPlans } from '../../services/plan.service'
import { getUserInfo } from '../../services/user.service'
import { observer } from 'mobx-react'
import plansStore from '../../stores/plans.store'
import { useKeycloak } from '@react-keycloak/web'
import userStore from '../../stores/user.store'

const MainRoute = observer(() => {
  const { reportPath } = config.path

  const { user, setUser } = userStore
  const { setCompanies } = companiesStore
  const { setPlans } = plansStore

  const { initialized, keycloak } = useKeycloak()

  const { pathname } = useLocation()
  const pathnames = pathname.split('/')

  const [isInitialDataLoading, setIsInitialDataLoading] = useState(true)

  useEffect(() => {
    if (keycloak.authenticated) {
      Promise.all([getUserInfo(), getAllCompanies(), getAllPlans()]).then(
        (responses) => {
          setUser(responses[0].data)
          setCompanies(responses[1].data)
          setPlans(responses[2].data)
          setIsInitialDataLoading(false)
        },
      )
    }
  }, [keycloak.authenticated, setCompanies, setPlans, setUser])

  if (initialized && !keycloak.authenticated) {
    keycloak.login({
      redirectUri: `${window.location.origin}${reportPath}${
        pathnames[2] ? `/${pathnames[2]}` : ''
      }${pathnames[3] ? `/${pathnames[3]}` : ''}`,
    })
    return <></>
  } else if (isInitialDataLoading) {
    return <LoadingBrandColor fullscreen={true} />
  }

  return (
    <Routes>
      {user.newUserState !== NewUserState.COMPLETE ? (
        <Route path="*" element={<NewUserRoute />} />
      ) : (
        <Route path="*" element={<SubRoute />} />
      )}
    </Routes>
  )
})

export default MainRoute
