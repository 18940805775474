import { BusinessType, Company } from '../types'
import axios, { AxiosResponse } from 'axios'

export const getAllCompanies = async (): Promise<AxiosResponse<Company[]>> => {
  return axios.get('v1/company')
}

export const createCompany = async (
  data: Pick<Company, 'name' | 'businessType' | 'country' | 'currency'>,
): Promise<AxiosResponse<Company>> => {
  return axios.post('v1/company', data)
}

export const updateCompany = async (
  data: Pick<
    Company,
    'companyId' | 'name' | 'businessType' | 'country' | 'currency'
  >,
): Promise<AxiosResponse<Company>> => {
  return axios.put('v1/company', data)
}

export const getBusinessTypeSuggestions = async (
  text: string,
): Promise<AxiosResponse<BusinessType[]>> => {
  return axios.get('v1/company/business-type-suggestion', {
    params: { text },
  })
}

export const upsertBusinessTypeKeyword = async (
  text: string,
  businessType: BusinessType,
): Promise<AxiosResponse<void>> => {
  return axios.post('v1/company/business-type-keyword', {
    text,
    businessType,
  })
}

export const deleteCompany = async (
  companyId: string,
): Promise<AxiosResponse<void>> => {
  return axios.delete('v1/company', { params: { companyId } })
}
