import './SearchBusinessTypeSelect.css'

import { BusinessType, SearchSelectState } from '../../types'
import { Dispatch, SetStateAction, useRef, useState } from 'react'
import { Flex, Select, Spin } from 'antd'
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons'
import {
  getBusinessTypeSuggestions,
  upsertBusinessTypeKeyword,
} from '../../services/company.service'

import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'

const SearchBusinessTypeSelect = ({
  value,
  setValue,
  options,
  setOptions,
  onChangeCallBack,
  filterOption = false,
  optionFilterProp = '',
  placeholder,
  notFoundTitle1,
  notFoundTitle2,
}: {
  value: BusinessType | undefined
  setValue: Dispatch<SetStateAction<BusinessType | undefined>>
  options: BusinessType[]
  setOptions: Dispatch<SetStateAction<BusinessType[]>>
  onChangeCallBack?: (optionSelected: BusinessType) => void
  filterOption?: boolean
  optionFilterProp?: string
  placeholder: string
  notFoundTitle1: string
  notFoundTitle2: string
}) => {
  const { t } = useTranslation()

  const timeOutId = useRef({})

  const [searchValue, setSearchValue] = useState('')
  const [state, setState] = useState<SearchSelectState>(SearchSelectState.EMPTY)

  const onSearch = (searchTerm: string) => {
    if (filterOption) {
      setSearchValue(searchTerm)
      setState(SearchSelectState.NOTFOUND)
      return
    }
    if (searchTerm.length > 40) return

    setSearchValue(searchTerm)
    typeof timeOutId.current === 'number' && clearTimeout(timeOutId.current)

    if (!isEmpty(searchTerm)) {
      timeOutId.current = setTimeout(() => {
        setOptions([])
        setState(SearchSelectState.LOADING)

        getBusinessTypeSuggestions(searchTerm)
          .then((responses) => {
            setOptions(responses.data)
            setState(SearchSelectState.NOTFOUND)
          })
          .catch(() => {
            setState(SearchSelectState.ERROR)
          })
      }, 1000)
    }
  }

  const onChange = (optionSelected: BusinessType) => {
    if (!filterOption && searchValue && optionSelected) {
      upsertBusinessTypeKeyword(searchValue, optionSelected)
    }
    setValue(optionSelected)
    onChangeCallBack && onChangeCallBack(optionSelected)
  }

  return (
    <Select
      showSearch
      filterOption={filterOption}
      optionFilterProp={optionFilterProp}
      suffixIcon={<SearchOutlined />}
      placeholder={placeholder}
      searchValue={searchValue}
      value={value}
      onSearch={onSearch}
      onChange={onChange}
      notFoundContent={
        state === SearchSelectState.EMPTY ? null : state ===
          SearchSelectState.LOADING ? (
          <Spin indicator={<LoadingOutlined />} />
        ) : (
          <Flex className="search-business-type-select-not-found-cmpt">
            <div
              className={`icon ${
                state === SearchSelectState.ERROR
                  ? 'icon-error'
                  : 'icon-not-found'
              }`}
            ></div>
            <span className="font-size-body-2-bold font-color-text-icon-main">
              {state === SearchSelectState.ERROR
                ? 'ขออภัย ไม่สามารถแสดงข้อมูลได้'
                : notFoundTitle1}
            </span>
            <span className="font-size-body-2-regular font-color-text-icon-detail">
              {state === SearchSelectState.ERROR
                ? 'โปรดค้นหาจากรายการที่เราเตรียมไว้'
                : notFoundTitle2}
            </span>
          </Flex>
        )
      }
      options={options.map((option: BusinessType) => ({
        label: t(option),
        value: option,
      }))}
      allowClear
    />
  )
}

export default SearchBusinessTypeSelect
