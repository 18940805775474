import './NewUserNavbar.css'

import { Flex } from 'antd'
import { observer } from 'mobx-react'

const NewUserNavbar = observer(() => {
  return (
    <Flex className="new-user-navbar-cmpt">
      <div className="logo">LOGO</div>
    </Flex>
  )
})

export default NewUserNavbar
