import { makeAutoObservable } from 'mobx'

class RedirectStore {
  stateRecheckPath = false

  constructor() {
    makeAutoObservable(this)
  }

  recheckPath = () => {
    this.stateRecheckPath = !this.stateRecheckPath
  }
}
const redirectStore = new RedirectStore()
export default redirectStore
