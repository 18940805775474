import './VerifyUserInfo.css'

import { Button, Flex, Form, FormProps, Input } from 'antd'
import { NewUserState, User } from '../../types'

import WelcomeUser from '../../components/WelcomeUser/WelcomeUser'
import { config } from '../../config'
import { updateUserInfo } from '../../services/user.service'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import userStore from '../../stores/user.store'

type UserInfoForm = Pick<User, 'firstName' | 'lastName'>

const VerifyUserInfoPage = () => {
  const { createCompanyPath } = config.path

  const { user, setUser } = userStore
  const { firstName, lastName, picture, email } = user

  const navigate = useNavigate()

  const [isUpdateUserInfoLoading, setIsUpdateUserInfoLoading] = useState(false)

  const onUpdateUserInfo: FormProps<UserInfoForm>['onFinish'] = (
    userInfoForm: UserInfoForm,
  ) => {
    setIsUpdateUserInfoLoading(true)
    updateUserInfo({
      ...userInfoForm,
      picture,
      newUserState: NewUserState.CREATE_COMPANY,
    }).then(() => {
      setUser({
        ...user,
        firstName: userInfoForm.firstName,
        lastName: userInfoForm.lastName,
      })
      navigate(createCompanyPath)
    })
  }

  return (
    <Flex className="verify-user-info-page">
      <WelcomeUser
        topic={'ตรวจสอบข้อมูล'}
        description={
          'ตรวจสอบข้อมูลส่วนตัว ชื่อ นามสกุล และอีเมล์ หากถูกต้องแล้ว กดปุ่ม “ถัดไป”'
        }
        form={
          <Form layout="vertical" onFinish={onUpdateUserInfo}>
            <Flex className="first-name-last-name-flex">
              <Form.Item
                label="ชื่อ"
                name="firstName"
                initialValue={firstName}
                rules={[{ required: true, message: 'โปรดกรอกชื่อ' }]}
                style={{ flex: 1 }}
              >
                <Input placeholder="กรอกชื่อ" allowClear />
              </Form.Item>
              <Form.Item
                label="นามสกุล"
                name="lastName"
                initialValue={lastName}
                rules={[{ required: true, message: 'โปรดกรอกนามสกุล' }]}
                style={{ flex: 1 }}
              >
                <Input placeholder="กรอกนามสกุล" allowClear />
              </Form.Item>
            </Flex>
            <Form.Item label="อีเมล์" name="email" initialValue={email}>
              <Input disabled={true} allowClear />
            </Form.Item>
            <Button
              block
              type="primary"
              htmlType="submit"
              size="large"
              loading={isUpdateUserInfoLoading}
            >
              ถัดไป
            </Button>
          </Form>
        }
      />
    </Flex>
  )
}

export default VerifyUserInfoPage
